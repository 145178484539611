<template>
  <div :class="[
    'form panel-container active',
     !panel.pinBottom && !panel.pinLeft && !panel.pinRight && !panel.pinTop && !panel.autoFit ? 'panel-container-default' : 'panel-container-no-bottom',
     state.panelEditing ? 'editing' : '',
     showBottomBar && !panel.hideBottomBar ? 'has-bottom-bar' : 'no-bottom-bar',
     showButtonContainer ? 'has-button-container' : 'no-button-container',
     step.elements && step.elements.length ? '' : 'empty',
     panel.autoFit ? 'auto-fit' : '',
     panel.pin ? getPanelPin(panel.pin) : '',
     isCollapsed ? 'collapsed-' + getOppositeDirection(laschePosition) : '',
     state.panelDragging ? 'no-transition' : '',
     //getPinDirection(panel),
     !isValid && !editable ? 'error-mode': '',
     //!editorMode ? getCollapseDirection(panel) : '',
      //!editorMode ? panelClasses[panel.uuid] : '',
   ]"
       :style="{
          width: panelWidth,
          minWidth: panel.minWidth ? panel.minWidth + (panel.maxWidthUnit ? panel.maxWidthUnit : 'px') + ' !important' : '',
          maxWidth: panel.maxWidth ? panel.maxWidth + (panel.maxWidthUnit ? panel.maxWidthUnit : 'px') + ' !important' : '',
          left: panelLeft,
          right: panel.xLeft || panel.xLeft === 0 ? 'unset' : 'var(--vform-default-vform-margin)',
          top: panel.pinBottom ? 'unset' : panel.y || panel.y === 0 ? panel.y + '%' : 'var(--vform-default-vform-margin)',
          bottom: panel.pinBottom ? '  var(--vform-bottom-bar-height)' : 'auto',
          height: panelHeight,
          'max-height': panelMaxHeight,
          fontSize: formSettings.fontSize ? formSettings.fontSize + 'em' : '0.95em',
          borderRadius: panel.borderRadius ? (panel.borderRadius + 'px') : '',
          }"
       :panelUuid="panel.uuid"
       :stepUuid="step.uuid"
  >
    <!--This displays the side elements to modify some panel options like pinning, autoFit etc.-->
    <panel-options
        v-if="editorMode && !state.panelDragging && !isCollapsed"
        :panel-edit-mode="state.panelEditing"
        :left="panelSide === 'right' ? '-30px' : 'auto'"
        :right="panelSide === 'left' ? '-30px' : 'auto'"
        :panel="panel"
        @switchToPanelEdit="$emit('switchToPanelEdit', panel)"
        @reRender="state.panelsRerenderKey++"
        @setPanelBottomBar="setPanelBottomBar"
        @setPanelAutoFit="setPanelAutoFit"
        @togglePinButton="togglePinButton"
        @removePanelPin="removePanelPin"
    />
    <collapsible-panel-lasche
        v-if="panel.pin && panel.collapsible"
        :panel="panel"
        :editor-mode="editorMode"
        :position="laschePosition"
        @toggle-collapse="handleToggleCollapse"
    />
    <div
        :class="[
              'form-inner d-flex',
              panel.autoFit ? 'auto-fit' : '',
              panel.isMaster && editorMode ? 'master-panel' : '',
              panel.collapsible ? 'collapse-handle-is-' + laschePosition : '',
              panel.collapsible ? 'collapsible' : '',
              //!editorMode && getCollapseDirection(panel) === 'collapse-bottom' ? 'collapse-panel-fadeout' : '',
              ]"
        :style="{
              backgroundColor: panel.backgroundColor ? panel.backgroundColor : 'var(--vform-editor-ui-primary-color)',
              color: panel.fontColor ? panel.fontColor : '#000',
              borderRadius: radius,
              borderBottomLeftRadius: showButtonContainer ? '0' : radius,
              borderBottomRightRadius: showButtonContainer ? '0' : radius,
              fontFamily: formSettings.font ? formSettings.font.name + (formSettings.font.systemFont ? '' : ' normal') : ''
          }"
    >
      <div
          v-if="state.panelEditing"
          :style="{backgroundColor: panel.identifierColor}"
          class="edit-indicator"
      >
        <div class="number">
          <icon v-if="panel.isMaster" class="white" type="globe-americas"/>
          <span v-else>{{ !panel.isMaster ? panelIndex + 1 : '' }}</span>
        </div>
      </div>
      <div class="form-inner-inner">
        <div :class="['full-height d-flex flex-column']">
          <div class="row">
            <!-- Displays everything relevant to the step. -->
            <!--<div class="col-12 pt-5">
             {{
                step.elements
                ? step.elements.map((item) => {
                  return getComponentName(item.formElementTypeString);
                })
                : "hallo"
                }}
              {{ step.elements.length + (vformConfig.global && vformConfig.global.elements ? vformConfig.global.elements.length : 0) }}
            </div>-->

            <div :class="['col-12 vform-tile-container', panel.hideTopPadding ? 'no-top-padding' : ''] ">
              <panel-element
                  v-if="projectInfo"
                  v-for="(element, index) in contentElements"
                  :ref="'element-' + element.uuid"
                  :key="element.uuid"
                  :element="element"
                  :num-of-elements="contentElements.length"
                  :index="index"
                  :editor-mode="editorMode"
                  :state="state"
                  :font="formSettings.font ? formSettings.font : null"
                  :font-color="panel.fontColor ? panel.fontColor: null"
                  :panel="panel"
                  :project-info="projectInfo"
                  :teams="teams"
                  :globals="globals"
                  :steps="steps"
                  :v-s-t-a-g-e-context="vSTAGEContext"
                  :step-form-data="stepFormData"
                  :last-slide-active="lastSlideActive"
                  :is-current-step="isCurrentStep"
                  :active-mode="activeMode"
                  :step="step"
                  :previous-element="contentElements[index - 1] ? contentElements[index - 1].uuid : null"
                  :offline-mode="state.offlineMode ? state.offlineMode : false"
                  @showElementProperties="(uuid) => {$emit('showElementProperties', uuid)}"
                  @increasePostLoadingCounter="$emit('increasePostLoadingCounter')"
                  @signOut="$emit('signOut')"
                  @editTemplate="(el) => {$emit('editTemplate', el)}"
                  @editProperty="(el) => {$emit('editProperty', el)}"
                  @edit="(el) => {$emit('edit', el)}"
                  @removeBlock="(el) => {$emit('removeBlock', el)}"
                  @goToSlide="(slideId, label, trigger) => $emit('goToSlide', slideId, label, trigger)"
                  @goToStep="(stepId, label, trigger) => $emit('goToStep', stepId, label, trigger)"
                  @goToProject="(projectId, projectName, stepId, trigger) => $emit('goToProject', projectId, projectName, stepId, trigger)"
                  @goToProjectSlide="(projectId, projectName, slideId, trigger) => $emit('goToProjectSlide', projectId, projectName, slideId, trigger)"
                  @next="(trigger) => $emit('next', trigger)"
                  @historyBack="$emit('historyBack')"
                  @goToFirstSlide="$emit('goToFirstSlide')"
                  @goToLastSlide="$emit('goToLastSlide')"
                  @goToFirstStep="$emit('goToFirstStep')"
                  @goToLastStep="$emit('goToLastStep')"
                  @checkout="checkout"
                  @storeData="(uuid, data) => $emit('storeData',uuid, data)"
              />
              <!--v-if="editable && editorMode || true"-->
            </div>
          </div>
          <block-drop-zone
              v-if="editable && editorMode && state.dragging"
              additional-classes="full-height-dropzone"
              drop-zone-id="LargeDropZone"
              :state="state"
              :panel="panel"
              :is-last="true"
              :previous-element="contentElements[contentElements.length - 1] ? contentElements[contentElements.length - 1].uuid : null"
              size="large"
          />
        </div>
      </div>
    </div>

    <!--BUTTON CONTAINER-->
    <div
        v-if="showButtonContainer"
        @mouseenter="() => {if (editable) {setHoverable(false);}}"
        @mouseleave="() => {if (editable) {setHoverable(true);}}"
        :class="[
            'row no-gutters button-container pb-2 pt-2 d-flex align-items-center align-self-end justify-content-between',
            panel.collapsible ? 'collapse-handle-is-' + laschePosition : '',
            ]"
        :style="{
                            backgroundColor: panel.bottomBackgroundColor
                                ? panel.bottomBackgroundColor : panel.backgroundColor ? panel.backgroundColor : '#fff',
                            borderTop: panel.bottomBackgroundColor
                                ? panel.bottomBackgroundColor : panel.backgroundColor ? panel.backgroundColor : '#fff',
                            borderBottomLeftRadius: radius,
                            borderBottomRightRadius: radius,
                            fontFamily: formSettings.font ? formSettings.font.name + (formSettings.font.systemFont ? '' : ' normal') : ''
                        }"
    >
      <!--SECONDARY DROPZONE GOES HERE-->
      <!--<div
          v-if="editorMode && state.dragging"
          :class="[
                'interactjs-drop-zone drop-zone fit-drop-zone w-100',
                state.dragging ? 'drop-zone-highlight' : '',
                !bottomElements.length && state.dragging ? 'make-zone-larger' : ''
              ]"
          is-bottom="true"
          dropZoneId="bottomDropZone"
          :panel="panel.uuid"/>-->
      <!-- v-if="editorMode && state.dragging"-->
      <block-drop-zone
          v-if="editable && editorMode && state.dragging"
          class="position-absolute"
          drop-zone-id="bottomDropZone"
          :state="state"
          :panel="panel"
          :is-last="false"
          :is-bottom="true"
          size="medium"
      />
      <panel-element
          v-if="projectInfo"
          v-for="(element, index) in bottomElements"
          :ref="'element-' + element.uuid"
          :index="index"
          :num-of-elements="bottomElements.length"
          :key="element.uuid"
          :element="element"
          :editor-mode="editorMode"
          :state="state"
          :font="formSettings.font ? formSettings.font : null"
          :font-color="panel.fontColor ? panel.fontColor: null"
          :panel="panel"
          :next-button-allowed="nextButtonAllowed"
          :project-info="projectInfo"
          :teams="teams"
          :globals="globals"
          :steps="steps"
          :v-s-t-a-g-e-context="vSTAGEContext"
          :step-form-data="stepFormData"
          :last-slide-active="lastSlideActive"
          :is-current-step="isCurrentStep"
          :active-mode="activeMode"
          :step="step"
          :previous-element="bottomElements[index - 1] ? bottomElements[index - 1] : null"
          :offline-mode="state.offlineMode ? state.offlineMode : false"
          @showElementProperties="(uuid) => {$emit('showElementProperties', uuid)}"
          @increasePostLoadingCounter="$emit('increasePostLoadingCounter')"
          @signOut="$emit('signOut')"
          @editTemplate="(el) => {$emit('editTemplate', el)}"
          @editProperty="(el) => {$emit('editProperty', el)}"
          @edit="(el) => {$emit('edit', el)}"
          @removeBlock="(el) => {$emit('removeBlock', el)}"
          @goToSlide="(slideId, label, trigger) => $emit('goToSlide', slideId, label, trigger)"
          @goToStep="(stepId, label, trigger) => $emit('goToStep', stepId, label, trigger)"
          @goToProject="(projectId, projectName, stepId, trigger) => $emit('goToProject', projectId, projectName, stepId, trigger)"
          @goToProjectSlide="(projectId, projectName, slideId, trigger) => $emit('goToProjectSlide', projectId, projectName, slideId, trigger)"
          @next="(trigger) => $emit('next', trigger)"
          @historyBack="$emit('historyBack')"
          @goToFirstSlide="(args) => $emit('goToFirstSlide', args)"
          @goToLastSlide="(args) => $emit('goToLastSlide', args)"
          @goToFirstStep="(args) => $emit('goToFirstStep', args)"
          @goToLastStep="(args) => $emit('goToLastStep', args)"
          @checkout="checkout"
          @storeData="(uuid, data) => $emit('storeData',uuid, data)"
      />

    </div>
    <div v-if="state.panelEditing && !panel.pinTop && !panel.pinRight && !panel.pinLeft && !panel.pinBottom && !panel.autoFit" class="ns-resize-handle"/>
    <div v-if="state.panelEditing" class="ew-resize-handle"/>
    <div v-if="state.panelEditing && !panel.pinTop && !panel.pinRight && !panel.pinLeft && !panel.pinBottom && !panel.autoFit" class="nwse-resize-handle"/>
  </div>
</template>

<script>
import PanelOptions from "@/components/vForm/viewer/PanelOptions.vue";
import PanelElement from "@/components/vForm/viewer/PanelElement.vue";
import Icon from "@/components/Icon.vue";
import CollapsiblePanelLasche from "@/components/vForm/viewer/CollapsiblePanelLasche.vue";
import BlockDropZone from "@/components/vForm/BlockDropZone.vue";
export default {
  name: "Panel.vue",
  components: {
    BlockDropZone,
    PanelOptions,
    PanelElement,
    Icon,
    CollapsiblePanelLasche
  },
  props: {
    /**
     * only for vSTAGE context: whether vform is currently displayed or not
     * if not, stop video and audio and other things happening in the background
     * */
    activeMode: {type: Boolean, default: true},
    panel: {type: Object, required: true},
    step: {type: Object, required: true},
    state: {type: Object, default: () => {return {}}},
    showButtonContainer: {type: Boolean, default: true},
    showBottomBar: {type: Boolean, default: true},
    editorMode: {type: Boolean, default: false},
    formSettings: {type: Object, required: true},
    bottomElements: {type: Array, default: () => {[]}},
    contentElements: {type: Array, default: () => {[]}},
    nextButtonAllowed: {type: Boolean, default: true},
    projectInfo: {type: Object, required: true},
    teams: {type: Array, required: true},
    vSTAGEContext: {type: Boolean, default: false},
    globals: {type: Object, default: () => {return []}},
    steps: { type: Array, default: () => { return []}},
    stepFormData: { type: Object, default: () => {return {}}},
    lastSlideActive: {type: Boolean, default: true},
    isCurrentStep: {type: Boolean, default: true},
    /**
     * Whether or not the step should be editable
     * **/
    editable: {type: Boolean, default: false},
    panelIndex: {type: Number, default: 0}
  },
  data() {
    return {
      defaultDropzoneHoverable: true,
      isCollapsed: this.panel.initiallyCollapsed || false,
      isValid: true,
      missingRequired: false,
    }
  },
  watch: {
    // todo: if initiallyCollapsed gets changed, this should be updated
    panel: {
      handler() {
        this.isCollapsed = this.panel.initiallyCollapsed || false;
      },
      deep: true
    }
  },
  computed: {
    radius() {
      return (this.panel.borderRadius ? this.panel.borderRadius : 0) + 'px';
    },
    panelSide() {
      const { pin, width, xLeft } = this.panel;

      // Case 1: Panel is pinned
      if (pin) {
        if (pin.toLowerCase().includes('right')) {
          return 'right';
        } else if (pin.toLowerCase().includes('left')) {
          return 'left';
        }
      }

      // Case 2: Panel is not pinned
      const panelWidth = width ? width : 30; // Default width is 30%
      const panelLeft = (xLeft || xLeft === 0) ? xLeft : 50; // Default to right half
      const panelRight = 100 - (panelLeft + panelWidth); // both in percentage, so the resulting percentage will be the right corner

      // just show the panel on the side where it has more space
      return panelLeft >= panelRight ? 'right' : 'left';

    },
    panelWidth() {
      const { width, version, pin, collapsible } = this.panel;
      const noWidth = !width && width !== 0;
      let correction = 0;
      if (pin && collapsible && (this.laschePosition === 'left' || this.laschePosition === 'right') || (!version && noWidth)) {
        correction = 30;
      }
      if (!version && noWidth) {
        return `calc(30% - ${correction}px)`;
      } else if (version && noWidth) {
        return '30%';
      } else if (version) {
        return `calc(${width}% - ${correction}px)`;
      } else {
        return `calc(${width}% - ${correction}px)`;
      }
    },
    panelHeight() {
      const {autoFit, height, pin} = this.panel;
      if(autoFit) {
        return 'auto !important';
      }
      if (height) {
        if (pin && pin.includes('bottom') && !height) {
          return 'calc(100% - var(--vform-bottom-bar-height))'
        }
        return height + '%';
      } else {
        if (this.showBottomBar) {
          return 'calc(100% - (var(--vform-default-vform-margin)*2) - var(--vform-bottom-bar-height))'
        } else {
          return 'calc(100% - (var(--vform-default-vform-margin)*2) - var(--vform-editor-carousel-height))'
        }
      }
    },
    panelMaxHeight() {
      const {pin, autoFit} = this.panel;
      if(pin === 'bottom' || autoFit) {
        return 'calc(100% - var(--vform-bottom-bar-height))';
      } else if(this.showBottomBar) {
        return 'calc(100% - var(--vform-bottom-bar-height))';
      }
      return '100%';
    },
    panelLeft() {
      const {xLeft, version} = this.panel;
      if (!xLeft && xLeft !== 0) {
        return 'unset';
      } else if (version) {
        return xLeft + '%';
      } else {
        // this is for legacy panels
        return 'calc(' + xLeft + '%  + 30px)';
      }
    },
    laschePosition() {
      const {pin} = this.panel;
      if (pin === 'top') return 'bottom';
      if (pin === 'bottom') return 'top';
      if (pin === 'right' || pin === 'topRight' || pin === 'bottomRight') return 'left';
      if (pin === 'left' || pin === 'topLeft' || pin === 'bottomLeft') return 'right';
      return '';
    },
  },
  methods: {
    getComponent(uuid) {
      return this.$refs["element-" + uuid] ? this.$refs["element-" + uuid][0] : null;
    },
    getPanelData() {
      const logs = [];
      const elements = [...this.bottomElements, ...this.contentElements];
      for (let i = 0; i < elements.length; i++) {
          const element = elements[i];
          const component = this.getComponent(element.uuid);
          if (component) {
            if (component.getData) {
              const data = component.getData();
              if (data) {
                logs.push(data);
              }
            }
          }
      }
      console.log('got logs', logs);
      return logs;
    },
    fillComponentData(data) {
      const elements = [...this.bottomElements, ...this.contentElements];
      for (let i = 0; i < elements.length; i++) {
        const {uuid} = elements[i];
        const component = this.getComponent(uuid);
        if (component && component.fillData) {
          const localData = data && data[uuid] ? data[uuid] : null;
          component.fillData(localData);
        }
      }
    },
    validate() {
      this.isValid = true;
      this.missingRequired = false;
      const elements = [...this.bottomElements, ...this.contentElements];
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const component = this.getComponent(element.uuid);
        if (component) {
          if (component.validate && this.lastSlideActive) {
            const isValid = component.validate();
            if (!isValid) {
             this.isValid = false;
            }
          }

          if (component.checkRequired && this.lastSlideActive) {
            const hasRequired = component.checkRequired();
            if (!hasRequired) {
              this.missingRequired = true;
              this.isValid = false;
            }
          }
        }
      }
      return this.isValid;
    },
    getJumpTargets() {
      const targets = [];
      const elements = [...this.bottomElements, ...this.contentElements];
      for (let i = 0; i < elements.length; i++) {
        const component = this.getComponent(elements[i].uuid);
        if (component && component.getJumpTarget) {
          const localTarget = component.getJumpTarget();
          if (localTarget) {
            targets.push(localTarget)
          }
        }
      }
      return targets;
    },
    getOppositeDirection(direction) {
      switch (direction) {
        case 'left': return 'right';
        case 'right': return 'left';
        case 'top': return 'down';
        case 'bottom': return 'up';
        default: return '';
      }
    },
    handleToggleCollapse(isCollapsed) {
      this.isCollapsed = isCollapsed;
    },
    /**
     * Splits topLeft into an array ["top", "left"]
     * if item is "left" only, it will just leave the array ["left"]
     * then returns strings for each item in the array as pin-[STRING]
     * */
    getPanelPin(pin) {
      const regex = /([a-z]+)([A-Z][a-z]+)/;
      const match = pin.match(regex);
      let res = [pin];
      if (match) {
        res = [match[1], match[2].toLowerCase()];
      }
      return res.map(item => {
        return 'pin-' + item;
      }).join(' ')
    },
    setHoverable(val) {
      this.defaultDropzoneHoverable = val;
    },
    setPanelAutoFit(autoFitBool) {
      this.$set(this.panel, "autoFit", autoFitBool)
    },
    setPanelBottomBar() {
      this.$set(this.panel, "hideBottomBar", !this.panel.hideBottomBar)
    },
    removePanelPin() {
      this.$set(this.panel, "pin", null);
    },
    togglePinButton(direction) {
      if (!this.panel.pin) {
        this.$set(this.panel, "pin", null);
      }
      if (this.panel.pin && this.panel.pin === direction) {
        this.$set(this.panel, "pin", null);
      } else {
        this.$set(this.panel, "pin", direction);
      }
    },
    checkout(config) {
      this.$emit('checkout', config);
    }
  }
}
</script>

<style lang="scss" scoped>
.full-height-dropzone {
  flex-grow: 1;
}
.form {
  min-width: 250px;
  position: absolute;
  width: 100%;
  height: calc(100% - (var(--vform-default-vform-margin) * 2));
  z-index: 55;
  font-size: 0.95rem;
  margin: auto;
  color: var(--vform-on-bright-color);
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;
  &.no-transition {
    transition: none;
  }

  &.error-mode {
    outline: 2px solid red;
  }

  &.active {
    right: var(--vform-default-vform-margin);
  }

  &.inactive {
    right: -100vw !important; // needs to be important because of locally set styles
    width: 0;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
  }

  &.no-bottom-bar {
    height: calc(
        90% - (var(--vform-default-vform-margin) * 2) -
        var(--vform-editor-carousel-height)
    );
  }

  &.has-bottom-bar {
    //margin-bottom: var(--vform-default-vform-margin);
    height: calc(
        100% - (var(--vform-default-vform-margin) * 2) -
        var(--vform-bottom-bar-height)
    );
  }
  &.collapsed-left {
    transform: translateX(calc(-100% + 25px));
    .form-inner, .button-container {
      opacity: 0;
    }
  }

  &.collapsed-right {
    transform: translateX(calc(100% - 25px));
    .form-inner {
      opacity: 0;
    }
  }

  &.collapsed-up {
    transform: translateY(calc(-100% + 25px));
    .form-inner {
      opacity: 0;
    }
  }

  &.collapsed-down {
    transform: translateY(calc(100% - 25px));
    .form-inner {
      opacity: 0;
    }
  }
}

.pin-top {
  top: 0 !important;
}

.pin-right {
  left: auto !important;
  right: 0 !important;
}

.pin-left {
  right: auto !important;
  left: 0 !important;
}

.pin-bottom {
  top: auto !important;
  bottom: var(--vform-bottom-bar-height) !important;
}

.form-inner {
  position: relative;
  flex: 1;
  padding-left: 18px;
  padding-right: 18px;
  background-color: var(--vform-editor-ui-primary-color);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  &.form-radius {
    border-radius: 3px;
  }
  &.collapse-handle-is-bottom {
    height: calc(100% - 25px);
    margin-bottom: 25px;
  }
  &.collapse-handle-is-top {
    height: calc(100% - 25px);
    margin-top: 25px;
  }
  &.collapse-handle-is-left {
    width: calc(100% - 25px);
    margin-left: 25px;
  }
  &.collapse-handle-is-right {
    width: calc(100% - 25px);
    margin-right: 25px;
  }
}
.has-button-container {
  .form-inner {
    &.collapse-handle-is-bottom {
      height: calc(100% - 25px);
      margin-bottom: 0;
    }
  }
  .button-container {
    &.collapse-handle-is-bottom {
      margin-bottom: 25px;
    }
  }
}

.master-panel {
  background-image: repeating-linear-gradient(45deg, rgba(47, 47, 67, 0.12) 0, rgba(47, 47, 67, 0.12) 1px, transparent 0, transparent 50%);
  background-size: 12px 12px;
  background-color: rgba(240, 255, 71, 0);
}
.form.empty {
  .form-inner {
    background-color: transparent;
  }
}
.form-inner-inner {
  min-height: 80%;
  width: 100%;
}
.button-container {
  width: 100%;
  border-top: 1px solid #ddd;
  display: flex;
  // flex-direction: column;
  position:relative;
  flex: 0;
  background-color: #fff;
  z-index: 100;
  padding-left: 18px;
  padding-right: 18px;
  margin: 0;
  &.bar-border-radius {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  &.collapse-handle-is-left {
    width: calc(100% - 25px);
    margin-left: 25px;
  }
  &.collapse-handle-is-right {
    width: calc(100% - 25px);
    margin-right: 25px;
  }
}
.ns-resize-handle {
  background: transparent;
  cursor: ns-resize;
  width: calc(100% - 25px);
  height: 25px;
  top: -25px;
  left: 0;
  position: absolute;
  z-index: 100;
  opacity: 0.4
}

.ew-resize-handle {
  background: transparent;
  cursor: ew-resize;
  width: 20px;
  height: calc(100% - 25px);
  top: 0;
  position: absolute;
  z-index: 100;
  right: 0;
}

.nwse-resize-handle {
  background: transparent;
  cursor: nwse-resize;
  width: 25px;
  height: 25px;
  bottom: 0;
  position: absolute;
  z-index: 101;
  right: 0;
}

.panel-container {
  pointer-events: all;
}
.step-panel-in-background {
  opacity: 0.8;
}
.edit-indicator {
  position: absolute;
  top: -6px;
  left: -6px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 150;
  box-shadow: 1px 1px 7px 4px rgb(0 0 0 / 13%);
  color: #fff;

  .number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (min-width: 576px) {
  .vform-tile-container {
    padding: 0.5rem !important;
  }
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 768px) {
  .vform-tile-container {
    padding: 0.7rem !important;
  }
}

// Large devices (desktops, less than 1200px)
@media (min-width: 992px) {
  .vform-tile-container {
    padding: 1rem !important;
  }
}

// Medium devices (tablets, less than 992px)
@media (min-width: 1200px) {
  .vform-tile-container {
    padding: 0.8rem !important;
  }
}

// X-Large devices (large desktops, less than 1400px)
@media (min-width: 1400px) {

  .vform-tile-container {
    padding: 1.1rem !important;
  }
}
// XXX-Large devices (larger desktops, 1400px and up)
@media (min-width: 2400px) {

  .vform-tile-container {
    padding: 1.1rem !important;
  }
}
</style>

