<template>
    <component
        :is="getComponentName(element.formElementTypeString)"
        :key="element.uuid"
        :ref="'element' + element.uuid"
        :active-mode="activeMode"
        :class="[
            getComponentName(element.formElementTypeString),
            'element-draggable form-element-context',
            state.draggedElementUuid === element.uuid ? 'element-dragging' : '',
        ]"
        :config="element"
        :disable-links="!!vSTAGEContext"
        :disabled="!lastSlideActive || state.panelEditing === true"
        :editable="editorMode && !state.dragging && state.activeColumn !== 'step-properties'"
        :editor-mode="editorMode"
        :editor-selected="state.activeElement === element.uuid"
        :element-uuid="element.uuid"
        :step-uuid="step.uuid"
        :element-index="index"
        :element-type="element.formElementTypeString"
        :font="font"
        :font-color="fontColor"
        :invisible="!nextButtonAllowed"
        :is-active="isCurrentStep"
        :panel-uuid="element.panelUuid"
        :panel="panel"
        :project-id="projectId"
        :step-form-data="stepFormData"
        :steps="steps"
        :session-id="$store.getters.getVFormSessionId"
        :v-s-t-a-g-e-context="vSTAGEContext"
        :globals="globals"
        :teams="teams"
        :organization-id="organizationId"
        :project-name="projectName"
        :num-of-elements-in-zone="element.bottomDropZone ? numOfElements : null"
        :data-element-id="element.uuid"
        :state="state"
        :offlineMode="offlineMode"
        @editProperty="$emit('showElementProperties', element.uuid)"
        @goToProject="(projectId, stepId, slideId) => {$emit('goToProject', projectId, stepId, slideId)}"
        @goToProjectSlide="(projectId, slideId) => {$emit('goToProjectSlide', projectId, slideId)}"
        @goToSlide="(id) => {$emit('goToSlide', id)}"
        @goToSlideNo="(no) => {$emit('goToSlide', no)}"
        @goToStep="(id) => {$emit('goToStep', id)}"
        @goToLastSlide="$emit('goToLastSlide')"
        @goToFirstSlide="$emit('goToFirstSlide')"
        @goToLastStep="$emit('goToLastStep')"
        @goToFirstStep="$emit('goToFirstStep')"
        @checkout="(args) => {$emit('checkout', args)}"
        @historyBack="$emit('historyBack')"
        @next="$emit('next')"
        @increasePostLoadingCounter="$emit('increasePostLoadingCounter')"
        @removeBlock="$emit('removeBlock', element.uuid)"
        @signOut="$emit('signOut')"
        @storeData="(elementId, data) => {$emit('storeData', elementId, data)}"
        @editTemplate="(el) => {$emit('editTemplate', el)}"
    >
      <block-drop-zone
          v-if="state.dragging && editorMode && !element.bottomDropZone && (!state.draggedElementUuid || state.draggedElementUuid !== element.uuid)"
          slot="dropzone"
          :class="['element-dropzone', element.uuid]"
          :drop-zone-id="'dz' + index + element.uuid"
          :state="state"
          :panel="panel"
          :is-last="index === numOfElements - 1"
          :is-first="index === 0"
          :previous-element="previousElement"
          :item-index="index"
          size="small"
      />
    </component>
</template>

<script>
import {getComponentName} from "@/components/vForm/utils";
import TEXTDISPLAY from "@/components/vForm/viewer/TEXTDISPLAY.vue";
import RADIOBUTTONS from "@/components/vForm/viewer/RADIOBUTTONS.vue";
import QR_CODE from "@/components/vForm/viewer/QR_CODE.vue";
import SHORT_ANSWER from "@/components/vForm/viewer/SHORT_ANSWER.vue";
import SEPARATOR from "@/components/vForm/viewer/SEPARATOR.vue";
import CHECKBOXES from "@/components/vForm/viewer/CHECKBOXES.vue";
import HEADLINE from "@/components/vForm/viewer/HEADLINE.vue";
import META_LIST_FILTER from "@/components/vForm/viewer/META_LIST_FILTER.vue";
import MEDIA_LOADER from "@/components/vForm/viewer/MEDIA_LOADER.vue";
import IMAGEDISPLAY from "@/components/vForm/viewer/IMAGEDISPLAY.vue";
import VIDEODISPLAY from "@/components/vForm/viewer/VIDEODISPLAY.vue";
import AUDIODISPLAY from "@/components/vForm/viewer/AUDIODISPLAY.vue";
import FORM_SUMMARY from "@/components/vForm/viewer/FORM_SUMMARY.vue";
import PROGRESS_TRACKER from "@/components/vForm/viewer/PROGRESS_TRACKER.vue";
import WEBSITE from "@/components/vForm/viewer/WEBSITE.vue";
import LOGOUT from "@/components/vForm/viewer/LOGOUT.vue";
import SENSOR from "@/components/vForm/viewer/SENSOR.vue";
import Icon from "@/components/Icon.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import BUTTON_ITEM from "@/components/vForm/viewer/BUTTON_ITEM.vue";
import LOGIN from "@/components/vForm/viewer/LOGIN.vue";
import VERTICAL_SPACE from "@/components/vForm/viewer/VERTICAL_SPACE.vue";
import LanguageSelector from "@/components/forms/LanguageSelector.vue";
import SignOut from "@/components/vForm/viewer/SignOut.vue";
import PDFDISPLAY from "@/components/vForm/viewer/PDFDISPLAY.vue";
import PART_INFO from "@/components/vForm/viewer/PART_INFO.vue";
import CODE_SNIPPET from "@/components/vForm/viewer/CODE_SNIPPET.vue";
import LIST_MATCH from "@/components/vForm/viewer/LIST_MATCH.vue";
import LIST_MATCH_TEXT_INPUT from "@/components/vForm/viewer/LIST_MATCH_TEXT_INPUT.vue";
import BlockDropZone from "@/components/vForm/BlockDropZone.vue";
import PART_LEGEND from "@/components/vForm/viewer/PART_LEGEND.vue";
export default {
  name: "PanelElement.vue",
  components: {
    BlockDropZone,
    TEXTDISPLAY,
    RADIOBUTTONS,
    QR_CODE,
    SHORT_ANSWER,
    SEPARATOR,
    CHECKBOXES,
    HEADLINE,
    META_LIST_FILTER,
    MEDIA_LOADER,
    IMAGEDISPLAY,
    VIDEODISPLAY,
    AUDIODISPLAY,
    FORM_SUMMARY,
    PROGRESS_TRACKER,
    WEBSITE,
    LOGOUT,
    SENSOR,
    Icon,
    LoadingSpinner,
    BUTTON_ITEM,
    LOGIN,
    VERTICAL_SPACE,
    LanguageSelector,
    SignOut,
    PDFDISPLAY,
    PART_INFO,
    CODE_SNIPPET,
    LIST_MATCH,
    LIST_MATCH_TEXT_INPUT,
    PART_LEGEND
  },
  props: {
    index: {type: Number, default: 0},
    /**
     * only for vSTAGE context: whether vform is currently displayed or not
     * if not, stop video and audio and other things happening in the background
     * */
    activeMode: {type: Boolean, default: true},
    editorMode: {type: Boolean, default: true},
    element: {type: Object, required: true},
    activeElementUuid: {type: String, default: null},
    state: {type: Object, required: true},
    font: {type: String, default: null},
    fontColor: {type: String, default: null},
    nextButtonAllowed: {type: Boolean, default: true},
    projectInfo: {type: Object, required: true},
    teams: {type: Array, required: true},
    vSTAGEContext: {type: Boolean, default: false},
    globals: {type: Object, default: () => {return []}},
    steps: { type: Array, default: () => { return []}},
    stepFormData: { type: Object, default: () => {return {}}},
    lastSlideActive: {type: Boolean, default: true},
    isCurrentStep: {type: Boolean, default: true},
    numOfElements: {type: Number, default: 0},
    step: {type: Object, required: true},
    previousElement: {type: String, default: null},
    panel: {type: Object, required: true},
    offlineMode: {type: Boolean, default: false}
  },
  computed: {
    organizationId() {
      if(this.projectInfo) {
        return this.projectInfo.organizationId;
      }
      return null;
    },
    projectName() {
      if(this.projectInfo) {
        return this.projectInfo.name;
      }
      return '';
    },
    projectId() {
      if(this.projectInfo) {
        return this.projectInfo.projectId;
      }
      return null;
    }
  },
  methods: {
    getComponentName,
    getComponent() {
      const refName = 'element' + this.element.uuid;
      return this.$refs[refName] ? this.$refs[refName] : null;
    },
    getData() {
      const component = this.getComponent();
      if(component && component.getData) {
        return component.getData();
      }
      return null;
    },
    fillData(data) {
      const component = this.getComponent();
      if(component && component.fillData) {
        component.fillData(data);
      }
    },
    validate() {
      return this.getComponent().validate();
    },
    checkRequired() {
      return this.getComponent().checkRequired();
    }
  },

}
</script>


<style scoped lang="scss">
.vform-preview {
  .form {
    padding-left: 0;
    position: static;
    padding-top: 0;
  }
}
.element-dropzone {
  position: absolute;
  top: -5px;
}

.panel-dragging {
  opacity: 0.8;
}
</style>