import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

//Perhaps replace with Vuex-Version: https://www.npmjs.com/package/vuex-i18n (not really like a store at all...)

import gdpr_de from './assets/texts/gdpr_de.txt';
import gdpr_en from './assets/texts/gdpr_en.txt';

import saas_agb_en from './assets/texts/saas_agb_en.txt';
import saas_services_en from './assets/texts/saas_services_en.txt';


/**
 * Translations
 * */
const messages = {
    en: {
        positioning: 'Positioning',
        showPrivateTeams: 'Show private teams',
        instanceMapperAlert: 'Attention: if you change the name property of a 3d part, this will be the new identifier to match your meta data or in case of updates with CAD files on how the names are matched. If this is not your intention, use DisplayName instead.',
        instanceMapperHinty: 'On the left you select the field on your 3D part and on the right you select whatever you want to update it with. You can also click together something like col1_col2',
        uploadMetaFile: 'Upload meta file',
        metaDataUpdaterHint: 'If you want to attach meta data to your 3D parts such as part number or size, you can do this here.',
        instanceUpdaterHint: 'If you want to change e.g. the names of your 3D parts, you can do this here.',
        haveToPinFirst: 'Collapsible elements have to be pinned first',
        partLegendHint: 'In order for this block to work you need to open up vSTAGE, mark some 3D parts as part list relevant – this view then will display those parts and you can also use this list for the word export.',
        HideExamples: 'Hide examples',
        ShowExamples: 'Show examples',
        ShowFileExamples: 'Show file examples',
        ShowRegexExamples: 'Show regex examples',
        ExamplePayload: 'Example Payload',
        UpdateMetaDataInfo: `Update meta data for 3D-Elements. This will attach meta sets and set values for the meta fields.`,
        makeCollapsible: 'Make collapsible',
        SetByTemplate: 'Set by template',
        saveContent: 'Save content as well',
        mfaLoginFailed: 'This did not work, please try again.',
        newLogin: 'New login',
        legacyLogin: 'Legacy login',
        default: 'Default',
        rulesBased: 'Rule based',
        isPreSelected: 'Default Selection',
        Suggestion: 'Suggestion',
        FindInstances: 'Find Instances',
        SelectUnselectAll: 'Select / unselect all',
        MultiEditing: 'Multi editing',
        removeFromAllItems: 'Remove from all items',
        useRegexInfo: 'If the original part of an assembly has the name abc_v_5 heisst and the updated has the name abc_v_6, it will need a regex to ignore the _v_5 part after the name to decide whether the abc_v_5 part needs to be updated using abc_v_6.',
        useRegex: 'Use Regex',
        useSmallImages: 'Use smaller images',
        publicWarning: 'Attention: if you publish this, the content of this presentation will be publicly available! You can always unpublish the already published content.',
        back: 'back',
        keepLoggingDisabled: 'Disable logging permanently',
        EditMode: 'Edit Mode',
        TestMode: 'Test Mode',
        LogDisabled: 'Logging Disabled',
        ModeQuestion: 'In which mode would you like to continue?',
        vformMode: {
            production: 'Live',
            test: 'Test'
        },
        askForTestOrLiveMode: 'On page load ask whether user wants to start in test mode (no logging) or live mode (logging)',
        NewLogAttachedSaveIt: 'We attached a new log for you - you have to save the form in order to make this change permanent',
        showInline: 'Show inline',
        noQuestion: 'No question',
        videoFileNotFound: 'Video file not found',
        useComponentLogs: 'Use component logs where available',
        renderGlobalPanels: 'Render global panels',
        logNoAccess: 'You do not have permission to load the attached log',
        logNotFound: 'The log could not be found',
        logIsEmpty: 'The log contains no data',
        PDFReport: 'PDF Report',
        deleteAllSelected: 'Delete selected files',
        selectUnselectAll: '(Un)select all visible items',
        showLogEntries: 'Show past log entries',
        Unknown: 'Default language',
        instanceMapInfo: 'Select here which of your columns will update which field on the instance',
        selectIdentifier: 'Select the item which identifies a part',
        onvHUBInstance: 'Instance on vHUB',
        inFile: 'In file',
        mapData: 'Map data',
        PostProcessingForInstance: 'Select format for data file',
        alwaysStartCollapsed: 'Show collapsed',
        upAxis: 'Up axis',
        OverallComplexity: 'Amount of details, only for asm, prt, sldasm, CATProduct, STEP and jt',
        errorMessages: {
            404: 'We are sorry, this page has not been found.',
            401: 'You do not have permission to access this page.'
        },
        convertToNewVersion: 'Convert to storage version',
        resetStyling: 'Reset styling',
        applyForAllSteps: 'Apply for all steps',
        resetAll: 'Reset All',
        showInstanceViewer: 'Show instance viewer',
        backToFrontPage: 'Back to front page',
        NoSummaryInEditMode: 'No summary in edit mode',
        Summary: 'Summary',
        EditForm: 'Edit',
        Question: 'Question',
        hideBottomBar: 'Hide bottom bar',
        autoFit: 'Auto fit',
        pinToTop: 'Pin to top',
        pinToTopLeft: 'Pin to top left',
        pinToTopRight: 'Pin to top right',
        pinToLeft: 'Pin to left',
        pinToRight: 'Pin to right',
        pinToBottom: 'Pin to bottom',
        pinToBottomLeft: 'Pin to bottom left',
        pinToBottomRight: 'Pin to bottom right',
        clearPin: 'Clear pin',
        ThemeColor: 'Theme Color',
        Images: 'Images',
        Videos: 'Videos',
        youWantToReplace: 'Item {itemName} already exists. Are you sure you want to replace the selected item?',
        HideBottomBar: 'Hide bottom bar',
        hasBorderRadius: 'Rounded corners',
        noTopPadding: 'Remove space on top',
        LoadAmountOfParts: 'Load number of parts',
        VIEWERS: 'VIEWERS',
        EDITORS: 'EDITORS',
        yourMediaAssets: 'Your media assets',
        MediaAssets: 'Media Assets',
        VMUsage: 'Usage of pipeline (3D imports)',
        numberOfEditors: 'Number of Editors',
        numberOfViewers: 'Number of Viewers',
        Users: 'Users',
        usedInstances: 'Used instances',
        CurrentItems: 'Recent Activities',
        components: 'components',
        yourComponentLimit: 'Your component limit',
        usedComponents: 'Used components',
        ScalingUnits: 'Scaling Units',
        NewestProjects: 'Newest Projects',
        NewestAssemblies: '3D Objects',
        pipelineErrorMsg: 'There was an error processing your request. Please contact us at support@sideeffects.ch',
        GlobalSettings: 'Global Settings',
        applyForAllItems: 'Apply for all items',
        yourDataGetsConverted: 'Your data will be extended with the new target formats, you will receive an email when this process is finished',
        lodLevel: {
          0: 'High quality only (lod 0)',
          1: 'High and medium quality (lod 0 and 1)',
            2: 'High, medium and low quality (lod 0 - 2)',
            3: 'High, medium, low and extra quality (lod 0 - 3)'
        },
        sfx: {
        },
        showingAllPivotsInDropdown: 'Showing all pivots in dropdown now',
        restoreVersionConfirm: 'Are you sure you want to reset the object to this version?',
        addGlbFiles: 'Add GLB format',
        showPreview: 'Show preview',
        VersionsBackups: 'Versions/Backups',
        LodLevels: 'Number of complexity levels',
        removeTheHull: 'Remove the hull',
        removeAllSmallParts: 'Remove small parts',
        Simplify: 'Simplify',
        MergeParts: 'Merge parts',
        RemoveParts: 'Remove parts',
        Advanced: 'Advanced',
        Corrections: 'Corrections',
        stringSearch: 'Search by name',
        metaSearch: 'Meta search',
        showMetaValues: 'Show meta info',
        showFlat: 'Show filtered only',
        rootNodeFile: 'Root file',
        lod: 'LOD levels',
        outputFormats: 'Output formats',
        files: 'Files',
        Korrekturen: 'Corrections',
        MsGuidesHint: 'Hints for MS Guides:<br /><ul><li>In order to publish to MS Guides, your project needs to contain an object of the type "MS Guides marker" and those 3D items which you wish to publish to MS Guides need to be marked in vSTAGE as such. Make sure you uploaded the latest version of your project before starting the publishing process.</li><li>MS guides only allows a maximum of 8 items or less depending on the complexity of the objects</li><li>To make your presentation on MS Guides perform with ease, upload only those parts you really need</li><li>Not all items are uploadable due to the limitation that MS Guides only allows .glb files and some items might not be available on vHUB in this format</li></ul>',
        VideoSizeHint: 'For videos bigger than 20MB we recommend using a stream service like vimeo or youtube for better loading performance',
        VideoHint: 'You find the video id usually in the url in your browser',
        YourIdHere: 'Your id here',
        VideoId: 'Video Id',
        EmbedCode: 'Embed Code',
        state: 'state',
        startsAt: 'starts at',
        expiresAt: 'expires at',
        DownloadAll: 'Download all',
        embedOnlyIfPublished: 'The embed link will be shown here if the project is published',
        RestartOrGoHome: 'Do you want to create a new session or just go to the first slide',
        GoTofirstslide: 'Go to the first slide',
        resetSession: 'Reset Session',
        theRequestedResourceWasNotFound: 'The requested resource could not be found.',
        SensorDisplay: 'Sensor Display',
        numbers: 'Numbers',
        SelectSensor: 'Select one or multiple sensors',
        webappPackage: 'Webapp type',
        attachNewLog: 'Attach new log',
        disableLoggingForThisSession: 'Disable logging for this session',
        yourCameraIsntWorkingTryThis: 'Use a different camera? Click here to fix it.',
        changeCamera: 'Change camera',
        selectedCamera: 'Selected camera',
        FullScreen: 'Full Screen',
        organizationsAndTeams: 'Organizations and Teams',
        personalData: 'Personal data',
        fitToContent: 'Fit to content',
        alwaysKioskMode: 'Show always in kiosk mode',
        baseProject: 'Base Project',
        goToApp: 'Go to app',
        reloadMessage: 'The content is being reloaded',
        kioskLeaveHint: 'To leave the kiosk mode, open this side panel, press on the title ({title}) for 10s until the controls reappear',
        addExisting: 'Add existing',
        create: 'Create',
        resetColor: 'Reset color',
        pleaseSelectAType: 'Please select a type',
        publishHint: 'The webapp is being published now. You will receive a notification when this process is finished.',
        setBackToDefault: 'Set back to default',
        deleteValue: 'Delete Value',
        setValueToEmpty: 'Set value to empty',
        clearValue: 'Clear Value',
        AdvancedFeatures: 'Advanced Features',
        open: 'Open',
        comingSoon: 'Coming soon',
        embedInLMS: 'Embed in LMS',
        sendAsLink: 'Send as a link',
        sureLeavePresentation: 'Are you sure you want to leave this presentation? All progress will be lost.',
        IdentifierFieldIsRequired: 'Identifier is required',
        addNextButtonForAllSteps: 'Add next button for all steps',
        alignment: 'alignment',
        qrCameraView: 'QR Camera View',
        addBackgroundColor: 'Add background color',
        setKioskMode: 'Enable kiosk mode',
        RefreshForm: 'Refresh form',
        noImageSelected: 'No image selected',
        noVideoSelected: 'No video selected',
        noAudioSelected: 'No audio selected',
        noPdfSelected: 'No pdf selected',
        currentProject: 'Current project',
        leavePresentation: 'Leave presentation',
        vformProjectLinkerHelptext: 'Projects will not appear if they have already a form attached',
        wrongQrCode: 'Wrong QR code',
        hasNextButtonOnEmptySlide: 'Has next button on slide without step',
        heightInPercent: 'Height in percent',
        pleaseChooseAPresentation: 'Choose a presentation',
        formSettings: 'Settings',
        CreateQrCode: 'Create QR code',
        SelectQrCode: 'Select QR code',
        NoneSelected: 'None selected',
        outputFormatsHelp: "For exports to MS Guides/Hololens you need glb files. When you select multiple output formats this can prolong the import process.",
        OutputFormats: "Output Formats",
        assemblyIsLocked: 'This item cannot be edited while a version is being created, restored or deleted.',
        versioningProcessStarted: 'The backup process has been started. You will receive an email when the process is finished.',
        makeAVersionFirst: 'Before you can update your 3d data, it is recommended to make a backup first. You will get an email when this process is finished. This can take up to several hours depending on the size of your 3D object. Updates without backup at your own risk.',
        restoreVersion: 'Restore this version',
        deleteVersion: 'Delete this version',
        previousSelection: 'previous selection',
        Update3dHint: 'This is a first version of the 3d data update. Make sure the items you want to keep in your CAD or other file have the same name like before or the element and its child components will be deleted.',
        Update3dData: 'Update 3D data',
        teamsNotMatching: 'Be aware that this item is not available for all teams. Missing team: ',
        addLoggingTable: 'create logging table',
        hasNextButton: 'has next button',
        addStep: 'add step',
        emphasized: 'Emphasized',
        showAsTiles: 'show as tiles',
        showAsTile: 'show as tile',
        showAsPercentage: 'use percentage',
        isUserIdentifierHint: 'For the data of this form to be logged there must be exactly one field that identifies the user. Enable the identifier property next to the input field that identifies the user (e.g. user id).',
        isUserIdentifier: 'identifies user',
        validateCorrectness: 'validate correctness',
        isRequired: 'is required',
        projectFilesNotAvailable: 'The project data for this project are not on the server. Please push your local project to the server and then try again.',
        vFormProjectHint: 'A vForm references slides from a project. Therefore it is not recommended to switch or remove the project because the references will be invalid and have to be changed to slides of the new project. One vForm can only be connected to one single project.',
        addTranslation: 'Add translation',
        editFile: 'edit',
        duplicateFile: 'copy',
        createUpdateDefaultJson: 'create/update default json',
        instanceLocalValueUsesGlobalValueFromAsset: "No value set. Will use global value from asset",
        assetGlobalValueUsesDefaultValue: "No value set. Using default value.",
        TileButtons: {
            imgvid: 'Bilder/Videos/Ton',
            webapp: 'Webapp',
            app: 'App',
            node: 'Node',
            material: 'Material',
            filter: 'ExportProfile',
            text: 'Text',
            model: '3D-Element',
            form: 'vForm',
            qrcode: 'QR-Code',
            report: 'Report',
            sensor: 'Sensor',
            feed: 'Feed',
            scenery: 'Scene',
            assembly: '3D-Group',
            link: 'Link',
            media: 'Media',
            helper: 'vHelper'
        },
        LoadStats: 'Load Stats',
        importAssetViaCSV: 'Import assets via CSV',
        importLog: 'Import log',
        gotIt: 'Got it',
        cookieNotification: 'By logging in you accept the use of cookies. The application will not work without the' +
            ' use of cookies.',
        gdpr_link: 'Data protection declaration',
        Accept: 'Accept',
        Deny: 'Deny',
        gdprExplanation: 'By clicking "accept" you accept the data protection declaration above.<br />When you click' +
            ' "deny" you will be logged out automatically.',
        gdprHint: 'To use vHub you need to accept the following data protection declaration:',
        gdpr: gdpr_en,
        saas_agb: saas_agb_en,
        saas_services: saas_services_en,
        RowLifetime: 'Row Lifetime',
        Days: 'Days',
        Hours: 'Hours',
        Minutes: 'Minutes',
        Seconds: 'Seconds',
        usePicker: 'Use picker tool',
        manualEntry: 'Manual entry',
        noFilterData: 'There is no data in this column to be filtered',
        pwdConditions: {
            atLeastOneUppercaseLetter: 'One uppercase letter',
            atLeastOneDigit: 'One digit',
            atLeastOneSpecialCharacter: 'One special character: {characters}',
            atLeastCharacters: 'Length between {min} and {max} characters',
        },
        repeatPassword: 'Repeat password',
        showPassword: 'Show Password',
        selectVM: 'Select Virtual Machine',
        LoadStorageUsage: 'Load storage usage',
        StorageUsage: 'storage usage',
        stopFeed: 'Stop feed',
        startFeed: 'Start feed',
        restartFeed: 'Restart feed',
        getFeedStatus: 'Get feed status',
        uploadvStageProject: 'Upload a project from vStage first in order to link it here',
        noDataYet: 'No data yet?',
        permissions: {
            logs: 'logs',
            organization: 'Organization',
            general: 'General',
            form: 'Form',
            user: 'User',
            metaset: 'MetaSet',
            team: 'Team'
        },
        a: 'active',
        h: 'hidden',
        p: 'processing',
        i: 'inactive',
        m: 'maintenance',
        createNewAssetBundle: 'Create Asset Bundle',
        UseMetaSetValuesForPivots: 'Use the values for pivots set in meta set: sfx.asset.transform (3D Transforms)',
        DefinePivotCorrections: 'Define pivot corrections',
        keepOriginalNamesAlert: 'Caution: when using this option, the resulting file is not usable in vSTAGE',
        keepOriginalNames: 'Maintain original names (Attention: the resulting file is not usable in vSTAGE)',
        centerPivots: 'Center pivots',
        pivotPlacement: 'Pivot placement (rotation point)',
        pivotComment: 'Source is the default state which means that the pivots will be used as present in the file and not be changed',
        isPreview: 'Is the preview',
        setAsPreview: 'Set as preview',
        valueCopiedToClipboard: 'The value got copied to your clipboard',
        New: 'New',
        DefineMerging: 'Define merging groups',
        State: 'State',
        ConnectionWaiting: 'The connection seem sto be slow, trying to connect to server.',
        ConnectionError: 'Could not connect to server, trying to reconnect.',
        RemoveMetaSetWarning: 'ATTENTION: if you remove a metaset from an object, all set values set for this object will be deleted as well',
        AttachOrRemoveMetaSet: 'Attach / remove meta sets',
        UpdateProcessStarted: 'The updated process is now in progress. You will receive a notification when it is finished.',
        NoExportProfileAccess: 'Your organization does not seem to have activated this feature yet – contact us for more information',
        WillDeleteAllRows: 'This will delete all rows in this table',
        for: 'for',
        AllowAccess: 'Allow access',
        SelectOrganizations: 'Select organizations',
        UserCreationHint: 'You can only create users for organizations in which you are an administrator.',
        Role: 'Role',
        AddEntry: 'Add entry',
        limitAccess: 'Limit access',
        fullAccessInOrganization: 'Free access for organization members',
        allowTeamAccess: 'Team access',
        isDefault: 'Is default',
        Documentation: 'Documentation',
        SignIn: 'Sign in',
        frontSloganRight: 'Start now.<br />Create your own<br />3D realtime',
        frontSloganLeft: 'Tomorrow\'s<br />3D Digital Twin<br />Cloud Platform. Now.',
        AssemblyProcessing: 'This 3D item is being processed – updates will be possible after the process is finished',
        UpdateSfxData: 'Update 3D-Data',
        XMLUpdateStart: 'Update meta data',
        SpecifyColumns: 'Column selection',
        GetEmailNotification: 'Get notification per email',
        MetaUpdateMode: 'On <b>reset</b> all data of the selected meta set will be removed<br />On <b>overwrite</b> existing values will be overwritten if new values are provided<br />On <b>extend</b> a value will only be set if no value is pre-existing',
        extend: 'extend',
        overwrite: 'overwrite',
        reset: 'reset',
        ChooseMaterialColumnIfOverwrite: 'Choose a material column if you wish to overwrite the default materials',
        ChooseColorColumnIfOverwrite: 'Choose a color column if you wish to overwrite the default colors',
        UploadMetaFile: 'Upload meta file',
        UpdateMetaData: 'Update meta data',
        UpdateHierarchy: 'Update hierarchy info',
        MetaData: 'Meta Data',
        ActiveServices: 'Active Services',
        NameIsDefinitive: 'Attention, the name cannot be changed after saving.',
        downloadAsCsv: 'Download as CSV',
        Type: 'Type',
        type: {
            backup: 'Backup',
            filter: 'filter',
            Version: 'Version',
            ExportProfile: 'Export profile',
            exportProfile: 'export profile',
            MetaSet: 'Meta set',
            MaterialMapper: 'Material Mapper',
            service: 'Service',
            team: 'Team',
            material: 'Material',
            user: 'User',
            scene: 'Scene',
            project: 'Project',
            VirtualMachine: 'Virtual Machine',
            element: 'Element',
            texture: 'Texture',
        },
        willAlsoBeDeleted: 'The following connected items will also be deleted',
        new: 'new',
        finished: 'finished',
        processing: 'processing',
        greatherThan: 'greater than',
        equals: 'equals',
        lessThan: 'less than',
        contains: 'contains',
        FileSize: 'File Size',
        DownloadFbx: 'Download FBX File',
        sourceProjectId: 'Source project id',
        SourceCode: 'Source Code',
        SelectedFile: 'Selected file',
        importSFX: 'Import 3D-Data',
        PostProcessingForMeta: 'Format of meta file',
        metaSetName: 'Name of the meta set',
        metaSetMaterialField: "Meta Set Material Field",
        metaSetColorField: "Meta Set Color Field",
        matMapperLoadFromServer: "LOAD FROM SERVER",
        matMapperLoadFromXML: "LOAD FROM XML",
        selectMaterialColumn: "Select Material Column",
        applyMaterialFromMapper: "Apply Material Mapping",
        applyMaterialTaskStarting: "Starting apply material process ...",
        applyMaterialTaskStarted: "Apply material process started. Please wait .. A message will be shown when the task is completed. You will also receive an email when task is complete. If this takes too long, please check task manager.",
        applyMaterialTaskCompleted: "Apply material process successfully completed.",
        materialName: "The client's material name",
        materialRemapWith: "Material on vHUB",
        loadMaterials: "Load Materials",
        saveMaterialMap: "Save Material Map",
        CreateNew: 'Create new',
        AllConnections: 'All Connections',
        Disclaimer: 'Disclaimer',
        ExportedProfiles: 'Exported Files',
        Preview: 'Preview',
        startProcess: 'Start process',
        ExportProfiles: 'Export profiles',
        createNewSFXFromExportProfile: 'Start export',
        DefineProfile: 'Define Profile',
        searchValues: 'Search values',
        chooseType: 'Choose {type}',
        createExportProfile: 'Create export profile',
        axisMultiplier: 'Rotation',
        pivotsField: 'Pivots',
        axisMultiplierDesc: 'If the axis of the model needs to be rotated, set the number in degrees (e.g.: x: 90, y: 180, z:0)',
        metaFilterInstructions: 'For numbers:<br /><ul><li>gt (greather than)</li><li>lt (less than)</li><li>eq (equal)</li></ul>For text:<br /><ul><li>eq (equal)</li><li>like</li></ul><b>Examples:</b> "gt 5" or "like *text*" or "eq text with spaces"',
        inheritedValues: 'Inherited values',
        globalValues: 'Global values',
        localValues: 'Local values',
        importStart: 'Start import',
        importConfig: 'Import configuration',
        identifierColumn: 'ID column',
        chooseModelIdField: 'Please choose the column from your meta file that contains the ID of the component/element in your model',
        filter3DItems: 'Filter 3D-Elements',
        filter3DItemsDescription: 'If you want only import some parts of your CAD data you can set some filters here.',
        metaFileDataSet: 'Dataset for meta data',
        metaFile: 'Meta File',
        File: 'File',
        file: 'file',
        "File(s)": 'File(s)',
        thisCanTakeSeveralSeconds: 'This can take up to {num} seconds. Depending on the filesize and your connection speed it can also take considerably longer.',
        selectedFiles: 'Selected File(s)',
        removeFile: 'Remove File',
        rootFile: 'Root File',
        dataDescriptionFile: 'Metadata file',
        Filter: 'Filter',
        Search: 'Search',
        pleaseChooseRoot: 'Please select the root component. (Uploaded components need to have a single root element).',
        pleaseChooseDataFile: 'Please select the data enrichment file if available. At the moment only XML-Files resulting from a Windchill search result export are supported.',
        Back: 'Back',
        Forward: 'Forward',
        folderStructureForUpload: 'Required folder structure: there should be no folders in the zip, just the files',
        attentionUploadStartsProcess: 'Attention: Upload starts importing-process right away',
        importHierarchy: 'Import hierarchy',
        cadConvert: 'Convert CAD Data',
        loadBefore: 'Load before',
        loadMore: 'Load more',
        jumpTo: 'Jump to',
        initiallyUploaded: 'Uploaded',
        transferringData: 'Transferring data to data center',
        transferComplete: 'Transfer data to data center complete',
        convertingData: 'Converting data',
        deliveringConvertedData: 'Delivering converted data',
        processingFinished: 'Processing finished',
        yourFileIsFinishedProcessing: 'Your data has been processed and is available under "Linked Data"',
        fileProcessingCannotUpload: 'Your file is being processed right now – you will not be able to upload another file until the processing is finished.',
        stepEmailDisclaimer: 'If you want to receive the notifications about the processing to another than your account email, you can enter a different address here.',
        yourFileIsGettingProcessed: 'Your file is being processed now, you will receive an email when the process is finished.',
        stepFileEmail: 'Email',
        stepFile: 'Step file',
        stepFileStatus: 'Step file processing status',
        reUploadFile: 'Upload a different file',
        pleaseWait: 'Please Wait',
        stepFileProcessingDisclaimer: 'When you start the processing, it will take up to 36 hours to process the file depending on the file size and complexity.',
        uploadWaitDisclaimer: 'Please wait – do not close or reload this window until the upload is finished',
        startProcessing: 'Start Processing',
        fileProcessing: 'File Processing',
        Processing: 'Processing',
        removePicture: 'Remove picture',
        changePicture: 'Change picture',
        editPicture: 'Edit picture',
        picture: 'Picture',
        addPicture: 'Add picture',
        helperTextSfxTypes: '<b>vHelper</b><br />\n' +
            'A vHelper is a model which is used as icons or for navigational purposes and so on. Examples are arrows, human figures and so on.\n' +
            '\n' +
            '<br /><br /><b>Model</b><br />\n' +
            'A model is a 3D Model which is used as a single entity like a fire extinguisher that you just want to place in a scene.\n' +
            '\n' +
            '<br /><br /><b>Assembly</b><br />\n' +
            'An assembly is a 3D Model which contains a hierarchy of multiple parts or objects and which will processed in a way that later you will have the ability to link data to certain parts in that hierarchy or show/hide parts of the assembly.',
        app: 'App',
        webapp: 'Webapp',
        chooseMediaType: 'Choose media type',
        nodata: 'There is no data available for this view',
        clearFilter: 'clear filter',
        from: 'from',
        until: 'until',
        searchMode: 'Search Mode',
        standard: 'standard',
        exactMatch: 'exact match',
        similar: 'similar',
        beginsWith: 'begins with',
        doesNotContain: "doesn't contain",
        justWaitAMinute: 'Please wait...',
        ExportAsPDF: 'Export as PDF',
        basicConfiguration: 'Basic Configuration',
        dataPreSelection: 'Data Preselection',
        labelConfigurator: 'Label Configurator',
        additionalConfiguration: 'Additional Configuration',
        chartName: 'Name of the chart',
        chartDescription: 'Chart Description',
        remainingCharacters: 'Remaining characters',
        limitToNCharactersHint: 'The maximal length of the text is {num} characters.',
        maxChars: 'Max {num} chars.',
        maxSelected: 'You can select only {num} item(s).',
        chooseLabelConfiguration: 'Choose the data you want to appear in the tooltip.',
        linkToOriginalTable: 'Go to table',
        instances: 'Local copies',
        addData: 'Add data',
        addrow: 'Add row',
        reloadMappingsWarningLoseChanges: "You will lose all your unsaved work. Continue?",
        url: 'URL',
        billingEmail: 'Billing Email',
        owner: 'Owner',
        visibility: 'Visibility',
        displayName: 'Display Name',
        maintext: 'Main Text',
        editQrcode: 'Edit QR code',
        noAccess: 'You have no permission to access that page',
        noAccessChangeOrg: 'You don\'t have the permission to access that page with the selected organization',
        AccessDenied: 'Access Denied',
        orgVisibilityTypes: {
            '0': 'Private',
            '1': 'Organization Level',
            '2': 'Public',
        },
        teamVisibilityTypes: {
            '0': 'private',
            '1': 'public',
            '2': 'global (new users get added automatically)'
        },
        mediaCategory: {
            '0': 'application',
            '1': 'audio',
            '2': 'font',
            '3': 'image',
            '4': 'message',
            '5': 'model',
            '7': 'pdf',
            '8': 'text',
            '9': 'video',
        },
        filter: {
            placeholderSearch: 'Suche',
            teamFilter: 'Teams',
            stateSelect: 'State',
            minValue: 'From',
            maxValue: 'To',
            search: 'Text search',
            placeholder: 'Enter text here',
            app: 'App',
            webapp: 'Webapp',
            assembly: '3D-Group',
            model: '3D-Element',
            helper: 'vHelper',
            tbl: 'Table',
            obj: 'Object',
            sensor: 'Sensor',
            feed: 'Feed',
            typeSelect: 'Type Selection',
            tagFilter: 'Tags',
            orgFilter: 'Organizations',
            fieldSelector: 'Filter a field',
        },
        fieldname: 'Field Name',
        insertBeforeField: 'Insert before the following field',
        order: 'Order',
        text: 'Text',
        string: 'Text',
        number: 'Number',
        timestamp: 'Timestamp',
        time: 'Time',
        typeToSelect: 'Type to select',
        index: 'Index',
        chooseSfxType: 'Please choose a type',
        chooseAppType: 'Please choose a type',
        choseDataSourceType: 'Please choose a type',
        choseQrcodeType: 'Please choose a type',
        choseFieldType: 'Please choose a type',
        deletePromptQuestion: 'Are you sure you want to delete the selected object?',
        deletePromptQuestionWithParams: 'Are you sure you want to delete "{name}"?',
        unlinkPromptQuestion: 'Are you sure you want to unlink the selected object and lose all local data from that link?',
        yes: 'Yes',
        abort: 'Cancel',
        addqrcode: 'Create QR Code',
        addQrcode: 'Create QR Code',
        username: 'Username',
        password: 'Password',
        user: {
            role: 'Role',
        },
        data: {
            lastUpdate: 'Last update',
            name: 'Name',
            edit: 'Edit',
        },
        Connect: 'Connect',
        edit: 'Edit',
        description: 'Description',
        media: 'media',
        name: 'Name',
        submit: 'Submit',
        Created: 'Created',
        Updated: 'Updated',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        telnr: 'Phone Number',
        withCountryCode: 'With Country Code (e.g. +41)',
        uploadfor: 'Upload for',
        ['Last change']: 'Last change',
        Contributors: 'Contributors',
        helper: 'vHelper',
        model: '3D-Element',
        assembly: '3D-Group',
        SFXData: '3D-Data',
        sfxdata: "3D data",
        qrcodes: "QR codes",
        materialmappers: "Mappers",
        qrcode: "QR Code",
        datasources: 'Datasources',
        showMorePivotPositions: 'Show all pivot positions',
        pivot: {
            source: 'Original',
            top: 'Top',
            left: 'Left',
            right: 'Right',
            center: 'Center',
            base: 'Bottom',
            front: 'Front',
            back: 'Back'
        },
        nav: {
            blueprintEdit: 'Edit',
            blueprint: 'BluePrint',
            export: 'Export',
            template_general: 'General',
            templates: 'Templates',
            regex: 'Regex',
            share: 'Share',
            gallery: 'Gallery',
            logs: 'Logs',
            projects_stats: 'Logs',
            cclights: 'CC LIGHT',
            appPanels: 'APP PANEL',
            sfxdata_meta_data: 'Meta Data',
            font: 'Font',
            profiles: 'Profile',
            exportprofiles: 'Profile',
            exportprofiles_general: 'General',
            projects_preview: '3D Web View',
            organizations_overview: 'Organizations',
            organizations_add: 'Add Organization',
            organization: 'Organization',
            organization_dashboard: 'Dashboard',
            help: 'Help',
            texture: 'Textures',
            texture_general: 'General',
            texturebackbutton: 'Back',
            blueprintsbackbutton: 'Back',
            add_texture: 'Add texture',
            projects_details: 'Share',
            projects_gallery: 'Gallery',
            projects_home: 'Home',
            versions: 'Versions',
            instance: 'Instance',
            instance_general: 'General',
            costOverview: 'Cost Overview',
            saasServices: 'SAAS SERVICES',
            saasAgb: 'SAAS AGB',
            csv_import: 'CSV Import',
            createVirtualMachine: 'Create VM',
            vmOverview: 'VM Overview',
            vmUsageAll: 'VM ALL',
            myVmUsage: 'VM Usage',
            vmUsage: 'VM Usage',
            adminproject: 'General Projects',
            taskmanager: 'Taskmanager',
            scene_general: 'General',
            scenebackbutton: 'Back',
            vmbackbutton: 'Back',
            scnee: 'Scene',
            scenes: 'Scenes',
            service_edit: 'General',
            rolebackbutton: 'Back',
            permissionsbackbutton: 'Back',
            permission_edit: 'General',
            permissions: 'Permissions',
            roles: 'Roles',
            material_general: 'General',
            materials: 'Materials',
            team_general: 'General',
            teams: 'Teams',
            organizing: 'Structure',
            qrcodes: 'QR-Codes',
            materialmappers: 'Mappers',
            services: 'Services',
            materialbackbutton: 'Back',
            materialmapbackbutton: 'Back',
            servicebackbutton: 'Back',
            teambackbutton: 'Back',
            sfxdata_export_profiles: 'Export profiles',
            files: 'Files',
            library_files: 'Files',
            library_3d_files: 'Files',
            access_denied: 'Access Denied',
            users: 'Users',
            tags: 'Tags',
            admin: 'Admin',
            projects: 'Projects',
            project: 'Projects',
            form_general: 'General',
            forms: 'Forms',
            formbackbutton: 'Back',
            media: 'Media',
            addMedia: 'Create media object',
            uploadMediaFile: 'Upload media file',
            apps: 'apps',
            app: 'apps',
            logout: 'logout',
            SFXData: '3D-Data',
            account: 'Account',
            organizations: 'Organizations',
            project_scene: "Scene",
            project_general: "General",
            project_3ddata: "3D-Data",
            project_media: 'Media',
            report_general: "General",
            sfxdata: "3D-Data",
            sfxdata_general: "General",
            sfxdata_general_assembly: "General",
            project_datasets: "Data Sources",
            project_datasources: "Data Sources",
            reports: "Reports",
            report: "Reports",
            sfxdata_data_sets: "Data Sources",
            sfxdata_media: "Media",
            sfxdata_assembly_update: "Update",
            texts: "Texts",
            text_general: "General",
            app_general: "General",
            media_general: "General",
            font_general: "General",
            user_general: "General",
            app_project: "Project",
            qrcode_general: "General",
            materialmapper_general: "General",
            data: "Data",
            data_tables: "Data Tables",
            data_sources: "Data Sources",
            data_sources_general: "General",
            datasets: "Datasets",
            dataset_general: "General",
            datasources: "Data Sources",
            resources: "Resources",
            library: "Library",
            modelbackbutton: "Back",
            assemblybackbutton: "Back",
            reportbackbutton: "Back",
            projectsbackbutton: "Back",
            projectbackbutton: "Back",
            userbackbutton: "Back",
            appbackbutton: "Back",
            webappbackbutton: "Back",
            sfxbackbutton: "Back",
            mediabackbutton: "Back",
            datasetbackbutton: "Back",
            datasourcebackbutton: "Back",
            metasetbackbutton: "Back",
            textbackbutton: "Back",
            qrcodebackbutton: "Back",
            materialmapperbackbutton: "Back",
            organizationbackbutton: "Back",
            sfxdata_linked_data: "Linked Data",
            project_linked_data: "Linked Data",
            sfxdata_connection_list: "Linked Data List",
            addnew3ddata: "Create new 3D Object",
            Upload: "Upload",
            Config: "Config",
            metasets: "Metasets",
            metaSets: "Metasets",
            webapp_linked_data: "Linked Data"
        },
        exportPrompt: {
            generatePartList: 'Generate part list table at the bottom',
            pdfOptions: 'PDF Options',
            export: "Export",
            word: "Word",
            wordOptions: "Word Options",
            pdf: "PDF",
            PDFReport: "PDF Report",
            template: "Template",
            autoGenerateLegend: "Auto Generate Legend",
            metaSet: "Meta Set",
            metaField: "Meta Field",
            download: "Download",
            pleaseSelectAlternativeNames:
                "Please select a metaset and metafield if you want to display alternative part names in the legend",
        },
        events: {
            instanceDataMetaValuesUpdated: 'Instance value updated',
            addedNextButtons: 'Next buttons have been added',
            instanceUpdated: 'The instance has been updated',
            metaValuesUpdated: 'A meta value has been updated',
            tagUpdated: 'The tag has been updated',
            metaFieldCreated: 'A meta field has been created',
            metaSetCreated: 'A meta set has been created',
            appUpdated: 'The application has been updated.',
            appCreated: 'A new app has been created.',
            appDeleted: 'The app has been deleted.',
            appMetaValuesUpdated: 'The value was updated.',
            dataSetCreated: 'A new data set has been created.',
            dataSetDeleted: 'The data set has been deleted.',
            dataSetUpdated: 'The data set has been updated.',
            dataSourceMetaValuesUpdated: 'The value was updated.',
            dataSourceCreated: 'A new data source has been created.',
            dataSourceDeleted: 'The data source has been deleted.',
            dataSourceUpdated: 'The data source has been updated.',
            datasetMetaValuesUpdated: 'The value was updated.',
            formUpdated: 'The form has been updated.',
            formCreated: 'The form has been created.',
            formDataCleared: 'The form data was cleared.',
            mediaCreated: 'A new media object has been created.',
            mediaDeleted: 'The media object has been deleted.',
            mediaUpdated: 'The media object has been updated.',
            mediaMetaValuesUpdated: 'The value was updated.',
            metaFieldDeleted: 'The MetaField was deleted.',
            metaFieldUpdated: 'The MetaField has been updated.',
            organizationUpdated: 'The organization has been updated',
            organizationCreated: 'The organization has been created.',
            organizationDeleted: 'The organization has been deleted.',
            pictureRemoved: 'The picture has been removed.',
            projectCreated: 'A new project has been created.',
            projectDeleted: 'The project has been deleted.',
            projectUpdated: 'The project has been updated.',
            projectMetaValuesUpdated: 'The value was updated.',
            qrcodeCreated: 'A new qr code has been created.',
            qrcodeDeleted: 'The qr code has been deleted.',
            qrcodeUpdated: 'The qr code has been updated.',
            qrcodeMetaValuesUpdated: 'The value was updated.',
            reportCreated: 'A new report has been created.',
            reportUpdated: 'The report has been updated.',
            reportMetaValuesUpdated: 'The value was updated.',
            sceneCreated: 'A new scene has been created.',
            sceneDeleted: 'The scene has been deleted.',
            sceneUpdated: 'The scene has been updated.',
            sceneMetaValuesUpdated: 'The value was updated.',
            sfxDataCreated: 'A new 3d object has been created.',
            sfxDataDeleted: 'The 3D object has been deleted.',
            sfxDataUpdated: 'The 3D Object has been updated.',
            sfxDataMetaValuesUpdated: 'The value was updated.',
            textUpdated: 'The text was updated.',
            textCreated: 'The text was created.',
            tagDeleted: 'The tag has been deleted.',
            tagsAdded: 'The tag was added',
            tagsRemoved: 'The tag has been removed.',
            tagCreated: 'The tag was created',
            userUpdated: 'The user data have been updated.',
            userCreated: 'A new user has been created',
            metaValuesUnset: 'Meta value deleted from database'
        },
        enternamehere: 'Name',
        backtooverview: 'back to overview',
        projectloading: 'Project loading',
        CONTRIBUTORS: 'CONTRIBUTORS',
        STATUS: 'STATUS',
        entertitlehere: 'Enter title here',
        createNewType: 'Create new {type}',
        selectedType: 'Selected {type}',
        addNewType: 'Add new {type}',
        deleteType: 'Delete {type}',
        createNewTypeFile: 'create new {type}',
        downloadType: 'Download {type}',
        updateType: 'Update {type}',
        enterdeschere: 'Enter description here',
        createnewapp: 'Create new app',
        createNewApp: 'Create new app',
        createNewProject: 'Create new project',
        createNewSfxdata: 'Import 3D-data',
        updateSfxdata: 'Update 3D-data',
        createNewMedia: 'Create new media',
        createNewUser: 'Create new user',
        createNewMetaField: 'Create new metaField',
        createNewMetaSet: 'Create new metaSet',
        createNewOrganization: 'Create new organization',
        createNewReport: 'Create new report',
        createNewText: 'Create new text',
        createNewQrCode: 'Create QR Code',
        createNewForm: 'Create new form',
        createNewMaterialMapper: 'New material mapper',
        SAVE: 'SAVE',
        REMOVE: 'REMOVE',
        makeCodePretty: 'Pretty Code',
        imageupload: 'Drag file here or click to upload',
        fileupload: 'Drag file here or click to upload',
        Media: "Media",
        Datasets: "Data Sources",
        ChooseProjectForApp: 'Choose a base project for this app',
        deletefield: 'DELETE FIELD',
        Anumberbetween: 'A number between {num} and {num2}',
        duplicateFileError: 'A file with the name {fileName} already exists. Please rename your file or choose another one to upload.',
        addnewfield: 'Add new field',
        addnewcolumn: 'Add new column',
        savefield: 'Save field',
        editfield: 'Edit field "{field}"',
        fieldCannotBeEditedWhenData: 'The field cannot be edited if there is any data filled in the table.',
        NotANumber: 'The entered value is not a number',
        createnew: 'Create new',
        filtering: 'Filtering',
        resources: 'Resources',
        library: "Library",
        addconnection: "Connecting data",
        removeconnection: "Verknüpfung wird entfernt",
        LinkedData: 'Linked Data',
        LocalLinkedItems: 'Linked Data',
        localLinkedItems: 'Linked Data',
        createNewDataSet: 'Create a new table',
        createNewDataSource: 'Create new datasource',
        MetaPanel: 'Meta Information',
        sfxEditFile: '3D File',
        mediaEditFile: 'Media File',
        textEditFile: '.po Translation File',
        localValuesDisclaimer: 'The local values override the global values for this one instance',
        assetMetaDisclaimer: 'Attention: if you edit these values they will change everyhwere where this asset is in use and its values not locally overwritten',
        uploadDisclaimer: 'Attention: if you upload a new file it will be replaced everywhere where it used',
        onlySingleLinkingDisclaimer: 'These items can only linked once – the instances column shows where it is already linked.',
        errors: {
            errorWhileParsingFile: 'There was an error while parsing the file',
            mfaCodeWrong: 'The given code was wrong, please try again',
            "Couldn't connect to server": "Couldn't connect to server",
            mustBeValueBetween: 'The value must be between {min} and {max}',
            metaSetName: 'The meta set cannot start with "sfx." because this is a protected namespace',
            PleaseSelectTheFormatOfYourMetaFile: 'Please select the format of your meta file or remove the meta file (adding meta data only works for the given formats)',
            PleaseSelectARootNode: 'Please select a root node for the given 3D-Data',
            alreadyExists: 'Duplicate name: There exists already a {type} with this name',
            metaSetNameAlreadyExists: 'Duplicate name: There exists already a meta set with this name',
            textNameAlreadyExists: 'Duplicate name: There exists already an asset with this name',
            connectionLimit: 'Only {num} elements can be linked for this content type. Please unlink one or more elements before linking a new one.',
            linkingNotPossible: 'The content could not be linked, there was an error.',
            cantEditRowWhenData: 'Fields cannot be edited if there are data rows containing data.',
            mustbeanumber: 'The value must be a number',
            atLeastCharacters: 'Please enter at least {num} characters',
            duplicateNameForAsset: 'Duplicate name: There exists already an asset with this name',
            duplicateNameForReport: 'Duplicate name: There exists already an report with this name',
            atLeastThreeCharacters: 'Please enter at least 3 characters',
            userNameAlreadyExists: 'A user with this name already exists, please choose another name',
            organizationNameAlreadyExists: 'An organization with this name already exists, please choose another name',
            emailAddressAlreadyExists: 'There already exists an account with this email address',
            mustBeAValidEmailAdress: 'Please enter a valid email address',
            bothPasswordsMustBeTheSame: `The two password fields don/'t match`,
            alphaNumSpaceOnly: 'Allowed characters: letters, numbers and the following . - _ °',
            alphaNumOnly: 'Allowed characters: letters, numbers, - and _ (no spaces)',
            alphaNumDot: 'Allowed characters: letters, numbers and .',
            orgNameOnly: 'Allowed characters: letters, numbers and -, and the - cannot be at either the beginning or the end',
            objectNameAlreadyExists: 'Duplicate name: There exists already an item with this name',
            atMostCharacters: 'Text too long: The maximum length is {num} characters ',
            appNameAlreadyExists: 'Duplicate name: There exists already an app with this name',
            projectNameAlreadyExists: 'Duplicate name: There exists already a project with this name',
            sfxdataNameAlreadyExists: 'Duplicate name: There exists already an asset with this name',
            formNameAlreadyExists: 'Duplicate name: There exists already an asset with this name',
            mediaNameAlreadyExists: 'Duplicate name: There exists already an asset with this name',
            datasetNameAlreadyExists: 'Duplicate name: There exists already a dataset object with this name',
            materialmapperNameAlreadyExists: 'Duplicate name: There exists already a material mapper object with this name',
            datasourceNameAlreadyExists: 'Duplicate name: There exists already an asset with this name',
            reportNameAlreadyExists: 'Duplicate name: There exists already an asset with this name',
            required: 'This field is mandatory',
            fileTypeNotAllowed: 'This file type can\'t be uploaded. Allowed file extensions: {fileTypes}',
            fileTooBig: 'Your file ({{filesize}}) exceeds the limit of {{maxFilesize}} MB',
            userPassOrNameIsWrong: 'Login failed: the username or password were rejected.',
            userIsBlocked: 'The user is blocked due to too many faulty login tries',
            mustBeNumeric: 'The value must be a number',
            mustBeAValidURL: 'Please enter a valid URL in the format https://www.test.ch',
        },
        forms: {
            isDefaultOrganization: 'Is default organization',
            default: 'Is default',
        },
        meta: {
            type: {
                int: 'Integer',
                str: 'String',
                json: 'JSON',
                float: 'Float',
                enum: 'Enumeration',
                bool: 'Boolean',
                clr: 'Color',
                v2: 'Vector 2',
                v3: 'Vector 3',
                v4: 'Vector 4',
                time: 'Time',
                datetime: 'Date and time',
                date: 'Date',
                project: 'Project',
                password: 'Password',
                xform3: 'Xform3',
                xform2: 'Xform2',
                email: 'Email',
                ip: 'IP',
                ipv6: 'Ipv6',
                uri: 'Uri',
                range: 'Range',
                asset: {
                    feed: 'Feed',
                    sensor: 'Sensor'
                },

            }
        },
        vform: {
            invalidEmailFormat: 'Invalid email address',
            legend: "Legend",
            legendPlaceholder: "Legend placeholder",
            noCaptionsDetected: "No captions detected",
            partLegend: "Partlist Legend",
            titleAlignment: "Title Alignment",
            textAlignment: "Text Alignment",
            partLegendLoading: "Loading Partlist Legend",
            video: 'Video',
            audio: 'Audio',
            sendEmailToAddress: 'Send email to',
            Checkout: 'Checkout',
            HistoryBack: 'History back',
            FirstSlide: 'First slide',
            LastSlide: 'Last slide',
            FirstStep: 'First step',
            LastStep: 'Last step',
            formSummary: 'Form Summary',
            vimeo: 'Vimeo',
            youtube: 'Youtube',
            other: 'Other',
            embed: 'URL Embed',
            directEmbed: 'Direct embed',
            upload: 'Direct Upload',
            chart_line: "Line",
            chart_text: "Text",
            sensor: 'Sensor Data',
            codeSnippet: 'Code',
            translationMessage: "{key} translation for: {content}",
            oldVersionMessage: "You have opened an older vForm, which was automatically converted to the latest vFrom version. Before saving, please check that all elements are correct and no information was lost.",
            steps: "Steps",
            button: "Button",
            login: "Login",
            logout: "Logout",
            editor: "Form Editor",
            checkoutButton: "Checkout Button",
            preview: "Preview",
            headline: "Headline",
            website: "Website",
            singlechoice: "single choice",
            multichoice: "multi choice",
            textfield: "Plain Text",
            shortanswer: "Text Input Field",
            helloworld: "Hello World",
            shortLorem: "Text ...",
            longLorem: "Short Answer ..",
            image: "Image",
            qrcode: "QR Code",
            stepControl: "Step Control",
            projectSwitch: "Project Switch",
            metaListFilter: "Meta List Filter",
            mediaLoader: "Media Loader",
            divider: "Divider",
            verticalSpace: "Vertical Space",
            slide: "Slide",
            UnnamedStep: "Unnamed Step",
            NoAction: "No Action",
            NextSlide: "Next Slide",
            GoToStep: "Go to Step",
            GoToSlide: "Go to Slide",
            newStep: "New Step",
            blocks: "Blocks",
            properties: "Properties",
            stepProperties: "Step",
            cannotSelectStepsFromOldVform: "You will be able to select steps from this project once you convert it to the new vform version.",
            nextStepButtonsAdded: "'Next Button' was successfully added to each step",
            link: "Link",
            iframe: "iFrame",
            newTab: "Open in new tab",
            loseChangesWarning: "You will lose all your changes. Continue?",
            doNotShowDeleteElementConfirmation: "Do not show this again",
            publishButton: "Publish Project",
            publishvformToAppMessage: "Publish started... You will receive an email with the outcome.",
            unpublishvformToAppMessage: "Unpublish started... You will receive an email with the outcome.",
            publishTragetWebAppRequired: "Please select target webapp.",
            selectTragetWebAppPlaceholder: "Select target webapp",
            unpublish: "Unpublish",
            republish: "Update",
            listOfWebAppsWhereVformIsPublished: "Published in:",
            textArea: "Multi line textfield",
            metaInfo: "Meta Info",
            partInfovStageOnly: "Part Info block is visible only in vStage",
            partInfo: "Part Info",
            selectInstanceFromScene: "You can select an instance to view more details",
            selectInstanceNotFound: "Selected Instance not found / no extra info",
            sureClearingFormData: "Are you sure you want to clear all form data?",
            hotspots: "Hotspots",
            learningDot: "Hotspot",
            possibleAnswers: "Possible answers",
            correct: "Correct",
            learningDots: "Basu-Dots",
            addLearningDot: "Add Label",
            listMatchpanel: "Case 1",
            listMatchimage: "Case 2",
            listMatch: "List Match",
            listMatchTextInput: "List Match - Text Input",
            addHotspot: "Add Hotspot",
            clearFormData: "Clear Form Data",
            hotspot: {
                popup: 'Popup',
                goto: 'Navigate'
            },
            buttonStyle: {
                Text: 'Text',
                Arrow: 'Arrow',
                TextAndArrow: 'Text & Arrow'
            },
            isMasterPanel: 'Is Global',
            localPanels: 'Local Panels',
            globalPanels: 'Global Panels',
            hideBottomBar: 'Hide Bottom Bar',
            autoFit: "Auto Fit",
            collapsible: "Collapsible",
            initiallyCollapsed: "Initial Collapsed",
            showHideSliders: "Show Additional options",
            noPin: "No pin",
            pinTop: "Pin Top",
            pinTopRight: "Pin Top Right",
            pinTopLeft: "Pin Top Left",
            pinLeft: "Pin Left",
            pinRight: "Pin Right",
            pinBottom: "Pin Bottom",
            pinBottomLeft: "Pin Bottom Left",
            pinBottomRight: "Pin Bottom Right",
            autoPlay: "Auto Play",
            loop: "Loop",
            templates: "Templates",
            missingTemplate: "Template missing or deleted. We recommend removing the currently attached template and selecting a new one.",
            removeTemplate: "Remove Template",
            noTemplatesFound: "No templates found.",
            viewAllTemplates: "View all templates",
            close: "Close",
            templatePreviewer: "Template Previewer",
            templateAssetNotFound: "Template asset for your organization not found",
            templateEditor: "Template Editor",
            templateType: "Template Type",
            noTemplateSelected: "No template selected.",
            templateIsEmpty: "Template name cannot be empty.",
            templateOverwrite: "You are about to overwrite an existing template.",
            createNewTemplate: "Create New Template",
            selectTemplate: "Select Template to Overwrite or Create New",
            saveTemplate: "Save Template",
            saveChanges: "Save Changes",
            renameTemplate: "Rename Template",
            deleteTemplateQuestion: 'Are you sure you want to delete the template "{templatename}"?',
        },

        isCorrectAnswer: "Is correct",
        isChecked: "Is checked",
        cclight: {
            notFound: "404 Not found",
            notFoundMessage: "Page not found. Please check the URL or contact the administrator.",
            unauthorized: "401 Unauthorized",
            unauthorizedMessage: "No access - please contact your administrator",
            goBack: "Go back",
        },
        rotLocScaleSetterPrompt: "Please note that this widget now uses the same coordinate system as vSTAGE. Saving this will result in the positioning to be adjusted for all vSTAGE presentations accordingly.",
    },
    de: {
        positioning: 'Positionierung',
        showPrivateTeams: 'Private Teams anzeigen',
        instanceMapperAlert: 'Achtung: Wenn Sie den Namen eines 3D Bauteils ändern, wird das der neue Identifier sein, mit dem MetaDaten appliziert werden,  oder im Fall eines Updates mit einer CAD Datei, wie die Namen verglichen werden. Falls dies nicht Ihre Absicht ist, nutzen Sie statt dessen DisplayName.',
        instanceMapperHinty: 'Auf der linken Seite können Sie die Eigenschaft des 3D Bauteils auswählen, die Sie updaten möchten, und auf der rechten Seite die Spalte aus Ihrer Datenquelle, die den Wert für das 3D Bauteil enthält. Hierbei sind auch Sachen wie spalte1_spalte2 möglich.',
        uploadMetaFile: 'Meta-Datei hochladen',
        metaDataUpdaterHint: 'Wenn Sie den 3D-Teilen Meta-Infos anhängen möchten wie z.B. Material, Farbe, etc., können Sie dies hier tun.',
        instanceUpdaterHint: 'Wenn Sie beispielsweise die Namen der 3D-Teile aktualisieren möchten, können Sie dies hier tun.',
        haveToPinFirst: 'Sie müssen zuerst das Panel anpinnen',
        partLegendHint: 'Damit dieser Block funktioniert, müssen in vSTAGE 3D-Teile als stücklistenrelevant markiert werden, dann werden in diesem Block die markierten Teile aufgelistet. Zudem kann diese Liste für den Word-Export genutzt werden.',
        HideExamples: 'Beispiele ausblenden',
        ShowExamples: 'Beispiele zeigen',
        ShowFileExamples: 'Datei-Beispiele zeigen',
        ShowRegexExamples: 'Regex-Beispiele zeigen',
        ExamplePayload: 'Beispiel Payload',
        UpdateMetaDataInfo: `Meta-Daten für 3D-Elemente aktualisieren. Das wird Metasets anhängen und Werte für die Metafields setzen.`,
        makeCollapsible: 'Collapsible machen',
        SetByTemplate: 'Vom Template vorgegeben',
        saveContent: 'Inhalte auch speichern',
        mfaLoginFailed: 'Das hat leider nicht geklappt, bitte versuchen Sie es erneut.',
        newLogin: 'Neues Login',
        legacyLogin: 'Altes Login',
        default: 'Standard',
        rulesBased: 'Regelbasiert',
        isPreSelected: 'Default Selection',
        Suggestion: 'Vorschlag',
        FindInstances: 'Instanzen finden',
        SelectUnselectAll: 'Alle selektieren / deselektieren',
        MultiEditing: 'Mehrfach-Bearbeitung',
        removeFromAllItems: 'Von allen Objekten entfernen',
        useRegexInfo: 'Wenn das originale Bauteil abc_v_5 heisst und das aktualisierte nun abc_v_6, dann braucht es einen Regex, der dafür sorgt, dass _v_5 ignoriert wird und somit abc_v_6 die neuere Version von abc_v_5 darstellt.',
        useRegex: 'Regex verwenden',
        useSmallImages: 'Verkleinerte Bilder verwenden',
        publicWarning: 'Achtung: wenn Sie dies publizieren, wird der Inhalt dieser Präsentation öffentlich zugänglich sein! Sie können die Veröffentlichung jederzeit rückgängig machen.',
        back: 'zurück',
        keepLoggingDisabled: 'Logging dauerhaft deaktivieren',
        EditMode: 'Editiermodus',
        TestMode: 'Testmodus',
        LogDisabled: 'Logging deaktiviert',
        ModeQuestion: 'In welchem Modus möchten Sie fortfahren?',
        vformMode: {
          production: 'Live',
          test: 'Test'
        },
        askForTestOrLiveMode: 'Beim Laden fragen, ob die Präsentation im Test-Modus (kein Logging) oder Live-Modus (Logging) gestartet werden soll',
        NewLogAttachedSaveIt: 'Wir haben für Sie ein neues Log angehängt. Sie sollten das Formular jetzt speichern.',
        showInline: 'Auf derselben Zeile',
        noQuestion: 'Ohne Frage',
        videoFileNotFound: 'Videodatei nicht gefunden',
        useComponentLogs: 'Komponenten-logs verwenden, wo verfügbar',
        renderGlobalPanels: 'Globale panels auch drucken',
        logNoAccess: 'Sie haben nicht die notwendige Berechtigung um das verknüpfte Log zu laden',
        logNotFound: 'Das Log konnte nicht gefunden werden',
        logIsEmpty: 'Das Log beinhaltet keine Daten',
        PDFReport: 'PDF Report',
        deleteAllSelected: 'Ausgewählte Dateien löschen',
        selectUnselectAll: 'Alle sichtbaren (un)selektieren',
        showLogEntries: 'Bisherige Logeinträge anzeigen',
        Unknown: 'Default language',
        instanceMapInfo: 'Wählen Sie hier, welche Ihrer Spalten welches Feld des Bauteils aktualisieren soll.',
        selectIdentifier: 'Spalte, die ein Bauteil identifiziert',
        inFile: 'In der Datei',
        onvHUBInstance: 'Instanz auf vHUB',
        mapData: 'Daten mappen',
        PostProcessingForInstance: 'Format für Daten-Datei auswählen',
        alwaysStartCollapsed: 'Zusammengeklappt anzeigen',
        upAxis: 'Up axis',
        OverallComplexity: 'Eingestellter Detailgrad, nur für asm, prt, sldasm, CATProduct, STEP und jt',
        errorMessages: {
            404: 'Diese Seite wurde nicht gefunden.',
            401: 'Sie haben keine Berechtigung, diese Seite anzuzeigen.'
        },
        convertToNewVersion: 'In Storage Version umwandeln',
        resetStyling: 'Styling zurücksetzen',
        applyForAllSteps: 'Auf alle Steps anwenden',
        resetAll: 'Alles zurücksetzen',
        showInstanceViewer: 'Instanzen-Viewer anzeigen',
        backToFrontPage: 'Zurück zur Startseite',
        NoSummaryInEditMode: 'Keine Zusammenfassung im Bearbeitungsmodus',
        Summary: 'Zusammenfassung',
        EditForm: 'Bearbeiten',
        Question: 'Frage',
        hideBottomBar: 'Fussblock verstecken',
        autoFit: 'Automatische Höhe',
        pinToTop: 'Oben anheften',
        pinToTopLeft: 'Oben Links anheften',
        pinToTopRight: 'Oben Rechts anheften',
        pinToLeft: 'Links anheften',
        pinToRight: 'Rechts anheften',
        pinToBottom: 'Unten anheften',
        pinToBottomLeft: 'Unten Links anheften',
        pinToBottomRight: 'Unten Rechts anheften',
        clearPin: 'Pin entfernen',
        ThemeColor: 'Theme Farbe',
        Images: 'Bilder',
        Videos: 'Videos',
        youWantToReplace: 'Das Objekt {itemName} existiert bereits. Sind Sie sicher, dass Sie es ersetzen möchten?',
        HideBottomBar: 'Bottom Bar verbergen',
        hasBorderRadius: 'Abgerundete Ecken',
        noTopPadding: 'Abstand am oberen Rand entfernen',
        LoadAmountOfParts: 'Anzahl Teile laden',
        VIEWERS: 'VIEWER',
        EDITORS: 'EDITOREN',
        yourMediaAssets: 'Ihre Medien-Assets',
        MediaAssets: 'Medien-Assets',
        VMUsage: 'Pipeline Nutzung (3D Import)',
        numberOfEditors: 'Anzahl Editoren',
        numberOfViewers: 'Anzahl Viewer',
        Users: 'Nutzer',
        usedInstances: 'Verwendete Instanzen',
        CurrentItems: 'Aktuelles',
        components: 'Komponenten',
        yourComponentLimit: 'Ihre Komponenten-Limite',
        usedComponents: 'Verwendete Komponenten',
        ScalingUnits: 'Skalierungseinheit',
        NewestProjects: 'Neuste Projekte',
        NewestAssemblies: '3D-Objekte',
        pipelineErrorMsg: 'Es gab ein Fehler beim Prozessieren Ihrer Daten. Bitte kontaktieren Sie uns unter support@sideeffects.ch',
        GlobalSettings: 'Globale Einstellungen',
        applyForAllItems: 'Auf alle Objekte anwenden',
        yourDataGetsConverted: 'Ihre 3D-Daten werden nun mit dem neuen Format erweitert, Sie erhalten eine Email, sobald dieser Prozess abgeschlossen ist.',
        lodLevel: {
            0: 'Nur hohe Qualität (lod 0)',
            1: 'Hohe und mittlere Qualität (lod 0 und 1)',
            2: 'Hohe, mittlere und niedrige Qualität (lod 0 - 2)',
            3: 'Hohe, mittlere, niedrige und extra-niedrige Qualität (lod 0 - 3)'
        },
        showingAllPivotsInDropdown: 'Das Dropdown beinhaltet nun alle Pivot-Positionen',
        restoreVersionConfirm: 'Sind Sie sicher, dass Sie das Objekt auf diese Version zurücksetzen möchten?',
        addGlbFiles: 'GLB format hinzufügen',
        showPreview: 'Vorschau anzeigen',
        VersionsBackups: 'Versionen/Backups',
        LodLevels: 'Anzahl Komplexitätsstufen',
        removeTheHull: 'Hülle entfernen',
        removeAllSmallParts: 'Kleinstteile entfernen',
        Simplify: 'Vereinfachen',
        MergeParts: 'Teile mergen',
        RemoveParts: 'Teile entfernen',
        Advanced: 'Für Fortgeschrittene',
        Corrections: 'Korrekturen',
        stringSearch: 'Suche nach Namen',
        metaSearch: 'Suche in Meta-Informationen',
        showMetaValues: 'Meta-Daten anzeigen',
        showFlat: 'Nur Gefilterte anzeigen',
        rootNodeFile: 'Root-Datei',
        lod: 'LOD-Stufen',
        outputFormats: 'Formate',
        files: 'Dateien',
        Korrekturen: 'Korrekturen',
        MsGuidesHint: 'Hinweise für MS Guides:<br /><ul><li>Um auf MS Guides zu publizieren, muss das Projekt einen Helper vom Typ "MS Guides Marker" beinhalten. Ebenso müssen Teile im vSTAGE als "MS Guides relevant" markiert werden, damit sie hochgeladen werden. Stellen Sie sicher, dass Sie die neuste Version Ihres Projekts auf vHUB hochgeladen haben, bevor Sie den Publish starten.</li><li>MS Guides erlaubt ein Maximum von 8 3D-Objekten oder weniger, abhängig von der Komplexität der Objekte</li><li>Damit Ihre Präsentation auf MS Guides performant läuft, laden Sie nur die Teile hoch, die Sie wirklich brauchen</li><li>Nicht alle 3D-Objekte können auf MS Guides hochgeladen werden, da MS Guides ausschliesslich .glb Dateien akzeptiert. Manche Objekte auf vHUB sind nicht in diesem Format vorhanden.</li></ul>',
        VideoSizeHint: 'Für Videos über 20MB Grösse empfehlen wir einen Streaming-Service wie Vimeo oder Youtube zu nutzen, um die Lade-Performance zu optimieren',
        VideoHint: 'Sie finden die Video ID normalerweise in der URL Ihres Browsers',
        YourIdHere: 'Video ID hier',
        VideoId: 'Video id',
        EmbedCode: 'Embed code',
        state: 'Status',
        startsAt: 'Gültig ab',
        expiresAt: 'Gültig bis',
        DownloadAll: 'Alle herunterladen',
        embedOnlyIfPublished: 'Der Einbettungs-Link erscheint hier, wenn das Projekt publiziert wurde',
        RestartOrGoHome: 'Möchten Sie eine neue Session beginnen oder zum Anfang zurückkehren?',
        GoTofirstslide: 'Zum Anfang zurückgehen',
        resetSession: 'Session zurücksetzen',
        theRequestedResourceWasNotFound: 'Die angeforderte Resource konnte nicht gefunden wurden.',
        SensorDisplay: 'Sensor Darstellung',
        numbers: 'Numerische Werte',
        SelectSensor: 'Sensor auswählen',
        webappPackage: 'Webapp Typ',
        attachNewLog: 'Neues Log anhängen',
        disableLoggingForThisSession: 'Logging für diese Sitzung deaktivieren',
        yourCameraIsntWorkingTryThis: 'Andere Kamera verwenden? Klicken Sie hier.',
        changeCamera: 'Kamera auswählen',
        selectedCamera: 'Ausgewählte Kamera',
        FullScreen: 'Vollbild',
        organizationsAndTeams: 'Organisationen und Teams',
        personalData: 'Persönliche Daten',
        fitToContent: 'Fit to content',
        alwaysKioskMode: 'Immer im Kioskmodus anzeigen',
        baseProject: 'Basis-Projekt',
        goToApp: 'Applikation aufrufen',
        reloadMessage: 'Der Inhalt wird neu geladen',
        kioskLeaveHint: 'Um den Kioskmodus zu verlassen, öffnen Sie das Sidepanel und halten Sie für 10s auf den Titel ({title}) geklickt, bis die Steuerelemente wieder auftauchen',
        addExisting: 'Bestehenden verwenden',
        create: 'Erstellen',
        resetColor: 'Farbe entfernen',
        pleaseSelectAType: 'Bitte wählen Sie einen Typ aus',
        publishHint: 'Die Webapp wird nun publiziert. Sie werden eine Email erhalten, sobald dieser Prozess abgeschlossen ist.',
        setBackToDefault: 'Standardwert wiederherstellen',
        deleteValue: 'Wert löschen',
        setValueToEmpty: 'Leeren Wert setzen',
        clearValue: 'Wert zurücksetzen',
        AdvancedFeatures: 'Advanced Features',
        open: 'Öffnen',
        comingSoon: 'Coming soon',
        embedInLMS: 'In LMS einbetten',
        sendAsLink: 'Als Link senden',
        sureLeavePresentation: 'Sind Sie sicher, dass Sie die Präsentation verlassen möchten? Alle Fortschritte werden verloren gehen, sofern Sie die Präsentation nicht abgeschlossen haben.',
        IdentifierFieldIsRequired: 'Identifier ist erforderlich',
        addNextButtonForAllSteps: 'Weiter-Button für alle Steps hinzufügen',
        alignment: 'Ausrichtung',
        qrCameraView: 'QR Kamera Ansicht',
        addBackgroundColor: 'Hintergrundfarbe hinzufügen',
        setKioskMode: 'Kioskmodus aktivieren',
        RefreshForm: 'Formular neu laden',
        noImageSelected: 'Kein Bild ausgewählt',
        noVideoSelected: 'Kein Video ausgewählt',
        noAudioSelected: 'Kein Audio ausgewählt',
        noPdfSelected: 'Kein PDF ausgewählt',
        currentProject: 'Aktuelles Projekt',
        leavePresentation: 'Präsentation verlassen',
        vformProjectLinkerHelptext: 'Wenn Projekte bereits einem Form zugewiesen wurden, werden sie hier nicht erscheinen. Es ist nur ein Form pro Projekt möglich.',
        wrongQrCode: 'Falscher QR-Code',
        hasNextButtonOnEmptySlide: 'Has next button on slide without step',
        heightInPercent: 'Höhe in Prozent',
        pleaseChooseAPresentation: 'Wählen Sie eine Präsentation aus',
        formSettings: 'Einstellungen',
        CreateQrCode: 'QR Code erstellen',
        SelectQrCode: 'QR Code auswählen',
        NoneSelected: 'Nichts ausgewählt',
        addLoggingTable: 'Logging-Tabelle erstellen',
        hasNextButton: 'Hat einen "weiter"-Button',
        addStep: 'Step hinzufügen',
        emphasized: 'hervorgehoben',
        showAsTiles: 'als Kacheln anzeigen',
        showAsTile: 'als Kachel anzeigen',
        showAsPercentage: 'in Prozenten',
        isUserIdentifierHint: 'Damit die Daten aus dieser vForm in eine Logtabelle eingetragen werden können, benötigt es exakt ein Feld, das den Nutzer identifiziert. Aktivieren Sie den "IDENTIFIER" beim Feld, welches den Nutzer identifiziert (z.B. Personalnummer).',
        isUserIdentifier: 'Identifiziert den Nutzer',
        validateCorrectness: 'Korrektheit prüfen',
        isRequired: 'Ist erforderlich',
        projectFilesNotAvailable: 'Die Daten für dieses Projekt sind nicht auf dem Server. Bitte pushen Sie Ihr lokales Projekt auf den Server und versuchen Sie es dann erneut.',
        vFormProjectHint: 'Die Bestandteile eines vForms beziehen sich auf Slides eines Projekts. Daher wird nicht empfohlen, das Projekt zu wechseln oder zu entfernen, ansonsten müssen die Slide-Referenzen manuell angepasst werden. Ein vForm kann nur mit einem einzigen Projekt verknüpft sein.',
        addTranslation: 'Übersetzung hinzufügen',
        outputFormatsHelp: "Für Exporte zu MS Guides/Hololens das GLB-Format ist zwingend notwendig. Wenn mehr Ausgabe-Formate ausgewählt sind, kann sich der Import-Prozess z.T. erheblich verlängern.",
        OutputFormats: "Ausgabe-Formate",
        assemblyIsLocked: 'Dieses Objekt kann nicht bearbeitet werden, während gerade eine Version erstellt, wiederhergestellt oder gelöscht wird.',
        versioningProcessStarted: 'Der Sicherungsprozess ist gestartet. Sie werden eine Email erhalten, sobald der Prozess abgeschlossen ist.',
        makeAVersionFirst: 'Es ist empfehlenswert, vorher eine Sicherheitskopie anzulegen. Nach Ende dieses Prozesses erhalten Sie eine Email. Dieser Prozess kann je nach Grösse des 3D-Objekts mehrere Stunden dauern. Update-Prozesse ohne vorherige Sicherheitskopie auf eigenes Risiko.',
        restoreVersion: 'Auf diese Version zurücksetzen',
        deleteVersion: 'Diese Version löschen',
        previousSelection: 'vorherige Auswahl',
        Update3dHint: 'This is a first version of the 3d data update. Make sure the items you want to keep in your CAD or other file have the same name like before or the element and its child components will be deleted.',
        Update3dData: '3D-Daten aktualisieren',
        teamsNotMatching: 'Achtung, dieses Item ist nicht für alle Teams zugänglich. Fehlendes Team: ',
        editFile: 'bearbeiten',
        duplicateFile: 'kopieren',
        createUpdateDefaultJson: 'create/update default json',
        instanceLocalValueUsesGlobalValueFromAsset: "No value set. Will use global value from asset",
        assetGlobalValueUsesDefaultValue: "No global value set. Using default value.",
        TileButtons: {
            imgvid: 'Images/Videos/Sound',
            webapp: 'Webapp',
            app: 'App',
            node: 'Node',
            material: 'Material',
            filter: 'ExportProfile',
            text: 'Text',
            model: '3D-Element',
            form: 'vForm',
            qrcode: 'QR-Code',
            report: 'Report',
            sensor: 'Sensor',
            feed: 'Feed',
            scenery: 'Szene',
            assembly: '3D-Gruppe',
            link: 'Link',
            media: 'Media',
            helper: 'vHelper'
        },
        LoadStats: 'Kennzahlen laden',
        importAssetViaCSV: 'Assets via CSV importieren',
        importLog: 'Import-Log',
        gotIt: 'Verstanden',
        cookieNotification: 'Durch den Klick auf den Login-Button erklären Sie sich mit Cookies einverstanden. Die' +
            ' Webapplikation funktioniert ohne den Einsatz von Cookies derzeit nicht.',
        gdpr_link: 'Datenschutzerklärung',
        Accept: 'Annehmen',
        Deny: 'Ablehnen',
        gdprExplanation: 'Mit Klick auf "annehmen" akzeptiere ich die obenstehende Datenschutzerklärung.<br />Beim' +
            ' Klick auf "ablehnen" werden Sie automatisch ausgeloggt.',
        gdprHint: 'Um vHub nutzen zu können, müssen Sie die folgende Datenschutzerklärung akzeptieren:',
        gdpr: gdpr_de,
        saas_agb: saas_agb_en,
        saas_services: saas_services_en,
        RowLifetime: 'Row Lifetime',
        Days: 'Tage',
        Hours: 'Stunden',
        Minutes: 'Minuten',
        Seconds: 'Sekunden',
        usePicker: 'Picker-Tool verwenden',
        manualEntry: 'Manueller Eintrag',
        noFilterData: 'In der ausgewählten Spalte sind keine Daten vorhanden, um zu filtern.',
        pwdConditions: {
            atLeastOneUppercaseLetter: 'Ein Grossbuchstabe',
            atLeastOneDigit: 'Eine Zahl',
            atLeastOneSpecialCharacter: 'Ein Sonderzeichen: {characters}',
            atLeastCharacters: 'Länge zwischen {min} und {max} Zeichen',
        },
        repeatPassword: 'Passwort wiederholen',
        showPassword: 'Passwort anzeigen',
        vmExplanation: 'Die virtuelle Maschine verarbeitet Ihre Daten',
        selectVM: 'Virtuelle Maschine auswählen',
        StorageUsage: 'Storage-Auslastung',
        LoadStorageUsage: 'Storage-Auslastung anzeigen',
        stopFeed: 'Stop feed',
        startFeed: 'Start feed',
        restartFeed: 'Restart Feed',
        getFeedStatus: 'Feed status',
        uploadvStageProject: 'Laden Sie zuerst ein Projekt von vStage hoch, um es hier zu verlinken.',
        noDataYet: 'Noch keine Objekte vorhanden?',
        permissions: {
            logs: 'logs',
            organization: 'Organisation',
            general: 'Allgemein',
            form: 'vForm',
            user: 'Benutzer',
            metaset: 'MetaSet',
            team: 'Team'
        },
        a: 'aktiv',
        h: 'verborgen',
        p: 'in Arbeit',
        i: 'inaktiv',
        m: 'in Wartung',
        createNewAssetBundle: 'Neues Asset Bundle',
        UseMetaSetValuesForPivots: 'Verwende für lokale Korrekturen das Feld "Bevorzugte Pivots" im Meta Set sfx.asset.transform (3D Transforms)',
        DefinePivotCorrections: 'Pivot Korrekturen',
        keepOriginalNamesAlert: 'Achtung: wenn diese Option angewählt ist, wird die resultierende Datei in vSTAGE nicht nutzbar sein',
        keepOriginalNames: 'Original-Namen beibehalten',
        centerPivots: 'Pivots zentrieren',
        pivotPlacement: 'Pivots-Position (Drehpunkt)',
        pivotComment: 'Source ist der Standard-Zustand, d.h. die Pivots werden von der Datei übernommen und nicht geändert',
        isPreview: 'Ist das verwendete Vorschaubild',
        setAsPreview: 'Als Vorschaubild verwenden',
        valueCopiedToClipboard: 'Der Wert wurde in Ihre Zwischenablage kopiert',
        New: 'Neu',
        DefineMerging: 'Merging-Gruppen definieren',
        State: 'Status',
        ConnectionWaiting: 'Die Internetverbindung ist langsam, bitte warten.',
        ConnectionError: 'Die Verbindung zum Server konnte nicht aufgenommen werden, versuche zu verbinden.',
        RemoveMetaSetWarning: 'ACHTUNG: Wenn Sie ein Metaset von einem Objekt entfernen, werden auch alle auf dem Zielobjekt gesetzten Werte gelöscht.',
        AttachOrRemoveMetaSet: 'Metasets hinzufügen / entfernen',
        ADD: 'Hinzufügen',
        UpdateProcessStarted: 'Der Aktualisierungsprozess ist gestartet. Sie werden eine Bestätigung erhalten, sobald der Prozess abgeschlossen ist.',
        NoExportProfileAccess: 'Ihre Organisation scheint diesen Service noch nicht freigeschaltet zu haben. Kontaktieren Sie uns für mehr Informationen.',
        WillDeleteAllRows: 'ACHTUNG: Hierdurch werden alle sichtbaren Einträge in dieser Tabelle gelöscht!',
        for: 'für',
        AllowAccess: 'Zugriff freigeben',
        SelectOrganizations: 'Organisationen auswählen',
        UserCreationHint: 'Sie können nur Benutzer für Organisationen erfassen, bei denen Sie Administrator-Rechte besitzen.',
        Role: 'Rolle',
        AddEntry: 'Eintrag hinzufügen',
        limitAccess: 'Zugriff einschränken',
        fullAccessInOrganization: 'Freier Zugriff für<br />Organisations-<br />mitglieder',
        allowTeamAccess: 'Teamzugriff',
        isDefault: 'Ist Standardwert',
        Documentation: 'Documentation',
        SideEffectsDisclaimer: 'Interactive 3D Solutions by <a href="https://sideeffects.ch" target="_blank">Side Effects AG</a>',
        SignIn: 'Sign In',
        frontSloganRight: 'Start now.<br />Create your own<br />3D realtime',
        frontSloganLeft: 'Tomorrow\'s<br />3D Digital Twin<br />Cloud Platform. Now.',
        AssemblyProcessing: 'Für diese 3D-Gruppe läuft gerade ein Verarbeitungsprozess. Updates werden möglich sein, sobald dieser Prozess abgeschlossen ist.',
        UpdateSfxData: '3D-Daten aktualisieren',
        XMLUpdateStart: 'Daten aktualisieren',
        SpecifyColumns: 'Spalten-Auswahl',
        GetEmailNotification: 'Per Email benachrichtigt werden',
        MetaUpdateMode: 'Beim <b>Zurücksetzen</b> werden sämtliche bestehenden Werte des gewählten Meta Sets vorher gelöscht<br />Beim <b>Überschreiben</b> werden bestehende abweichende Werte überschrieben<br />Beim <b>Erweitern</b> werden nur Werte erweitert, wo keine bestehenden Werte vorhanden sind',
        extend: 'erweitern',
        overwrite: 'überschreiben',
        reset: 'zurücksetzen',
        ChooseMaterialColumnIfOverwrite: 'Spalte Materialbezeichnung (überschreibt Standard-Werte, wenn ausgewählt)',
        ChooseColorColumnIfOverwrite: 'Spalte Farbbezeichnung (überschreibt Standard-Werte, wenn ausgewählt)',
        UploadMetaFile: 'Meta-Datei hochladen',
        UpdateMetaData: 'Meta-Daten aktualisieren',
        UpdateHierarchy: 'Hierarchie-Infos aktualisieren',
        MetaData: 'Meta-Daten',
        ActiveServices: 'Aktive Services',
        NameIsDefinitive: 'Achtung, der Name kann später nicht mehr geändert werden.',
        downloadAsCsv: 'Als CSV herunterladen',
        type: {
            backup: 'Sicherheitskopie',
            filter: 'Filter',
            Version: 'Version',
            ExportProfile: 'Exportprofil',
            exportProfile: 'Exportprofil',
            MetaSet: 'Meta Set',
            service: 'Service',
            team: 'Team',
            material: 'Material',
            user: 'User',
            Role: 'Rolle',
            scene: 'Szene',
            project: 'Projekt',
            VirtualMachine: 'Virtuelle Maschine',
            element: 'Element',
            texture: 'Textur',
        },
        willAlsoBeDeleted: 'Die folgenden verknüpften Elemente werden ebenfalls gelöscht',
        new: 'neu',
        finished: 'abgeschlossen',
        processing: 'in Verarbeitung',
        greatherThan: 'grösser als',
        equals: 'ist gleich',
        lessThan: 'weniger als',
        contains: 'enthält',
        FileSize: 'Dateigrösse',
        DownloadFbx: 'FBX Datei herunterladen',
        sourceProjectId: 'Projekt-ID',
        SourceCode: 'Quellcode',
        SelectedFile: 'Ausgewählte Datei',
        importSFX: 'Import 3D-Daten',
        PostProcessingForMeta: 'Format der Metadaten-Datei',
        metaSetName: 'Name des Metasets',
        metaSetMaterialField: "Metaset Feld für Material",
        matMapperLoadFromServer: "VOM SERVER LADEN",
        matMapperLoadFromXML: "VOM XML IMPORTIEREN",
        selectMaterialColumn: "Spalte für Material auswählen",
        materialName: "Material-Name des Kunden",
        materialRemapWith: "Material von vHUB",
        loadMaterials: "Materialien laden",
        saveMaterialMap: "Material-Zuweisung speichern",
        CreateNew: 'Neu erstellen',
        AllConnections: 'Alle Verknüpfungen',
        Disclaimer: 'Hinweis',
        ExportedProfiles: 'Exportierte Dateien',
        Preview: 'Vorschau',
        startProcess: 'Prozess starten',
        ExportProfiles: 'Exportprofile',
        createNewSFXFromExportProfile: 'Export starten',
        DefineProfile: 'Profil definieren',
        searchValues: 'Suchwerte',
        chooseType: '{type} auswählen',
        createExportProfile: 'Exportprofil erstellen',
        Mode: 'Modus',
        axisMultiplier: 'Rotation',
        pivotsField: 'Pivots',
        axisMultiplierDesc: 'Falls die Achse des Modells korrigiert werden muss, diese hier in Grad angeben (z.B.: x: 90, y: 180, z:0)',
        metaFilterInstructions: 'Für Zahlen:<br /><ul><li>gt (greather than/grösser als)</li><li>lt (less than/kleiner als)</li><li>eq (equal/gleich)</li></ul>Für Text:<br /><ul><li>eq (equal/gleich)</li><li>like (ähnlich wie)</li></ul><b>Beispiele:</b> "gt 5" oder "like *Chrom*" or "eq Metall"',
        inheritedValues: 'Geerbte Werte',
        globalValues: 'Globale Werte',
        localValues: 'Lokale Werte',
        importStart: 'Import starten',
        importConfig: 'Import-Konfiguration',
        identifierColumn: 'ID Spalte',
        chooseModelIdField: 'Geben Sie die Spalte an, welche die Bauteilbenennungen beinhaltet.',
        filter3DItems: '3D-Elemente Filtern',
        filter3DItemsDescription: 'Wenn Sie nur bestimmte Teile Ihrer CAD-Daten importiert haben möchten, können Sie hier anhand Ihrer Meta-Daten Filtern',
        metaFileDataSet: 'Tabelle Meta-Daten',
        metaFile: 'Meta-Daten Datei',
        File: 'Datei',
        file: 'Datei',
        "File(s)": 'Datei(en)',
        thisCanTakeSeveralSeconds: 'Das kann bis zu {num} Sekunden dauern. Je nach Dateigrösse und Internetverbindung auch länger.',
        selectedFiles: 'Ausgewählte Datei(en)',
        removeFile: 'Datei entfernen',
        rootFile: 'Root-Datei',
        dataDescriptionFile: 'Metadaten-Datei',
        Filter: 'Filtern',
        Search: 'Suchen',
        pleaseChooseRoot: 'Bitte wählen Sie die Root-Komponente. (Hochgeladene Modelle müssen zwingend ein einzelnes Root-Element haben).',
        pleaseChooseDataFile: 'Bitte wählen Sie die Datei, welche Zusatzinformationen zu den einzelnen Elementen beinhaltet. (Derzeit werden nur XML-Dateien unterstützt, welche als Windchill Suchresultat exportiert wurden)',
        Back: 'Zurück',
        Forward: 'Vorwärts',
        folderStructureForUpload: 'Zip-File: es dürfen im Zip keine Ordner vorhanden sein, ausschliesslich Dateien',
        attentionUploadStartsProcess: 'Achtung: Upload startet den Import-Prozess sofort',
        importHierarchy: 'Import Hierarchie',
        cadConvert: 'CAD Daten konvertieren',
        loadBefore: 'Zurück',
        loadMore: 'Mehr laden',
        jumpTo: 'Springe zu',
        initiallyUploaded: 'Datei hochgeladen',
        transferringData: 'Datentransfer zum Datenzentrum',
        transferComplete: 'Transfer zum Datenzentrum komplett',
        convertingData: 'Konvertierung der Daten',
        deliveringConvertedData: 'Auslieferung der konvertierten Daten',
        processingFinished: 'Verarbeitung abgeschlossen',
        yourFileIsFinishedProcessing: 'Ihre Daten wurden verarbeitet und sind nun unter "Verknüpfte Daten" verfügbar.',
        fileProcessingCannotUpload: 'Ihre Datei wird gerade verarbeitet – Sie können erst wieder eine neue Datei hochladen, wenn der Verarbeitungsprozess abgeschlossen ist.',
        stepEmailDisclaimer: 'Wenn Sie die Benachrichtigung an eine andere Email-Adresse als Ihre Account-Adresse gesendet bekommen möchten, können Sie hier eine Adresse angeben.',
        yourFileIsGettingProcessed: 'Ihre Datei wird nun verarbeitet. Sie werden eine Email erhalten, wenn die Verarbeitung abgeschlossen ist. Sie können dieses Browserfenster ohne Probleme schliessen.',
        stepFile: 'Step Datei',
        stepFileStatus: 'Step Datei Status',
        stepFileEmail: 'Email',
        reUploadFile: 'Neue Datei hochladen',
        pleaseWait: 'Bitte warten',
        stepFileProcessingDisclaimer: 'Wenn Sie die Verarbeitung starten, kann es je nach Dateigrösse bis zu 36h dauern, bis die Datei konvertiert ist.',
        uploadWaitDisclaimer: 'Bitte warten – bitte NICHT das Fenster schliessen oder neu laden, bis der Upload beendet ist.',
        startProcessing: 'Verarbeitung starten',
        fileProcessing: 'Dateiverarbeitung',
        Processing: 'In Bearbeitung',
        removePicture: 'Bild entfernen',
        changePicture: 'Bild ändern',
        editPicture: 'Bild bearbeiten',
        picture: 'Bild',
        addPicture: 'Bild hinzufügen',
        helperTextSfxTypes: '<b>vHelper</b><br />\n' +
            'Ein vHelper ist ein Modell, welches als Icon, für Navigationszwecke oder als Pointer eingesetzt wird, z.B. Pfeile, menschliche Figuren und so weiter.\n' +
            '\n' +
            '<br /><br /><b>Model</b><br />\n' +
            'Ein Model ist ein 3D Modell welches als einzelne Komponente behandelt wird, z.B. ein Feuerlöscher oder einen Mülleimer, den Sie in der Szene platzieren möchten.\n' +
            '\n' +
            '<br /><br /><b>Baugruppe</b><br />\n' +
            'Eine Baugruppe ist ein 3D-Modell welches aus mehreren Komponenten besteht und so verarbeitet wird, dass später mit einzelnen Unterkomponenten Daten verlinkt werden oder einzelne Komponenten ein- und ausgeblendet werden können.',
        app: 'App',
        webapp: 'Webapp',
        chooseMediaType: 'Medien-Typ auswählen',
        nodata: 'Für diese Ansicht sind derzeit keine Daten vorhanden',
        clearFilter: 'Filter leeren',
        from: 'von',
        until: 'bis',
        searchMode: 'Suchmodus',
        standard: 'Standard',
        exactMatch: 'Exakt gleich',
        similar: 'Ähnlich',
        beginsWith: 'Beginnt mit',
        doesNotContain: "Enthält nicht",
        justWaitAMinute: 'Bitte warten...',
        ExportAsPDF: 'Als PDF exportieren',
        basicConfiguration: 'Basis-Konfiguration',
        dataPreSelection: 'Daten-Vorauswahl',
        labelConfigurator: 'Beschriftungs-Konfigurator',
        additionalConfiguration: 'Weitere Konfigurationen',
        chartName: 'Name des Charts',
        chartDescription: 'Beschreibung',
        remainingCharacters: 'Verbleibende Zeichen',
        limitToNCharactersHint: 'Der Text darf maximal {num} Zeichen lang sein.',
        maxChars: 'Maximal {num} Zeichen.',
        maxSelected: 'Es können nur {num} Element(e) ausgewählt werden.',
        chooseLabelConfiguration: 'Wählen Sie aus, welche Daten im Tooltip angezeigt werden sollen.',
        linkToOriginalTable: 'Verknüpfte Tabelle',
        instances: 'Lokale Kopien',
        addData: 'Daten hinzufügen',
        addrow: 'Zeile hinzufügen',
        reloadMappingsWarningLoseChanges: "Sie werden alle ungespeicherten Änderungen verlieren. Fortfahren?",
        url: 'URL',
        owner: 'BesitzerIn',
        billingEmail: 'Rechnungs-Email',
        visibility: 'Sichtbarkeit',
        displayName: 'Display Name',
        maintext: 'Haupttext',
        editQrcode: 'QR Code bearbeiten',
        AccessDenied: 'Kein Zugriff',
        noAccess: 'Sie haben nicht die Berechtigung, auf diese Seite zuzugreifen',
        noAccessChangeOrg: 'Sie haben für die ausgewählte Organisation nicht die Berechtigung, auf diese Seite zuzugreifen',
        filter: {
            placeholderSearch: 'Search',
            teamFilter: 'Teams',
            stateSelect: 'Status',
            minValue: 'Von',
            maxValue: 'Bis',
            search: 'Textsuche',
            placeholder: 'Suchtext eingeben',
            app: 'App',
            webapp: 'Webapp',
            assembly: '3D Gruppe',
            model: '3D Element',
            helper: 'vHelper',
            tbl: 'Tabelle',
            obj: 'Objekt',
            sensor: 'Sensor',
            feed: 'Feed',
            typeSelect: 'Typ',
            tagFilter: 'Tags',
            orgFilter: 'Organisationen',
            fieldSelector: 'Feld filtern',
        },
        fieldname: 'Feldbezeichnung',
        insertBeforeField: 'Vor dem folgenden Feld einfügen',
        order: 'Reihenfolge',
        text: 'Text',
        string: 'Text',
        number: 'Zahl',
        timestamp: 'Zeitstempel',
        time: 'Zeit',
        typeToSelect: 'Tippen um zu filtern',
        index: 'Index',
        chooseSfxType: 'Bitte wählen Sie einen Typus aus.',
        chooseAppType: 'Bitte wählen Sie einen Typus aus.',
        choseFieldType: 'Bitte wählen Sie einen Typus aus.',
        choseDataSourceType: 'Bitte wählen Sie einen Typus aus.',
        choseQrcodeType: 'Bitte wählen Sie einen Typus aus.',
        deletePromptQuestion: 'Sind Sie sicher, dass Sie das gewählte Objekt löschen möchten?',
        deletePromptQuestionWithParams: 'Sind Sie sicher, dass Sie "{name}" löschen möchten?',
        unlinkPromptQuestion: 'Sind Sie sicher, dass Sie die gewählte Verknüpfung aufheben möchten? Lokale Daten, die mit dieser Verknüpfung zusammenhängen, gehen dabei möglicherweise verloren.',
        yes: 'Ja',
        abort: 'Abbrechen',
        addQrcode: 'QR Code erstellen',
        addqrcode: 'QR Code erstellen',
        username: 'Benutzername',
        password: 'Passwort',
        user: {
            role: 'Rolle',
        },
        data: {
            lastUpdate: 'Letzte Aktualisierung',
            name: 'Name',
            edit: 'Bearbeiten',
        },
        enternamehere: 'Name',
        Connect: 'Verknüpfen',
        edit: 'Edit',
        description: 'Beschreibung',
        media: 'Medien',
        name: 'Name',
        submit: 'Speichern',
        logout: 'abmelden',
        Created: 'Erstellt am',
        Updated: 'Aktualisiert am',
        firstName: 'Vorname',
        lastName: 'Nachname',
        email: 'Email',
        telnr: 'Telefon-Nummer.',
        withCountryCode: 'Mit Länder-Vorwahl (z.B. +41)',
        uploadfor: 'Datei hochladen für',
        ['Last change']: 'Letzte Änderung',
        Contributors: 'Beteiligte',
        sfxdata: "3D-Daten",
        qrcodes: "QR Codes",
        qrcode: "QR Code",
        datasources: 'Datenquellen',
        MetaPanel: 'Meta Informationen',
        sfxEditFile: '3D-Datei',
        mediaEditFile: 'Media-Datei',
        textEditFile: '.po Übersetzungs-Datei',
        localValuesDisclaimer: 'Lokale Werte überschreiben die globalen Werte für diese eine Instanz',
        assetMetaDisclaimer: 'Achtung: wenn Sie hier Werte überschreiben, werden diese überall dort ersetzt, wo dieses Asset in Verwendung und nicht durch lokale Werte überschrieben ist',
        uploadDisclaimer: 'Achtung: wenn Sie hier eine neue Datei hochladen, wird sie überall ersetzt, wo dieses Asset in Verwendung ist',
        onlySingleLinkingDisclaimer: 'Die untenstehenden Objekte können nur einmalig verlinkt werden.',
        errors: {
            errorWhileParsingFile: 'Datei konnte nicht gelesen werden',
            mfaCodeWrong: 'Der Code ist leider ungültig. Bitte versuchen Sie es erneut.',
            "Couldn't connect to server": "Serververbindung fehlgeschlagen",
            mustBeValueBetween: 'Der Wert muss zwischen {min} und {max} sein',
            metaSetName: 'Der Name des Metasets darf nicht mit "sfx." beginnen - das ist ein reservierter Namespace',
            alreadyExists: 'Doppelter Name: Es existiert bereits ein {type} mit diesem Namen.',
            PleaseSelectTheFormatOfYourMetaFile: 'Bitte wählen Sie das Format Ihrer Meta-Daten oder entfernen Sie die Auswahl der Meta-Datei (die Anreicherung der Meta-Daten funktioniert nur für die angegebenen Formate)',
            PleaseSelectARootNode: 'Bitte wählen sie das Root-Element Ihrer 3D-Daten',
            metaSetNameAlreadyExists: 'Doppelter Name: Es existiert bereits ein Metaset mit diesem Namen.',
            reportNameAlreadyExists: 'Doppelter Name: Es existiert bereits eine Statistik mit diesem Namen.',
            textNameAlreadyExists: 'Doppelter Name: Es existiert bereits ein Asset mit diesem Namen.',
            connectionLimit: 'Es können nur {num} Elemente verlinkt werden. Bitte entfernen Sie ein oder mehrere Elemente und versuchen Sie es erneut.',
            linkingNotPossible: 'Der ausgewählte Inhalt konnte nicht verlinkt werden.',
            cantEditRowWhenData: 'Felder können nicht mehr editiert werden, wenn bereits Datenzeilen vorhanden sind.',
            mustbeanumber: 'Der angegebene Wert muss eine Zahl sein',
            duplicateNameForAsset: 'Doppelter Name: Es existiert bereits ein Objekt mit diesem Namen',
            duplicateNameForReport: 'Doppelter Name: Es existiert bereits ein Report mit diesem Namen',
            atLeastThreeCharacters: 'Der Suchbegriff muss mindestens 3 Zeichen lang sein',
            userNameAlreadyExists: 'Ein Nutzer mit diesem Namen existiert bereits, bitte wählen Sie einen anderen Namen',
            organizationNameAlreadyExists: 'Eine Organisation mit diesem Namen existiert bereits, bitte wählen Sie einen anderen Namen',
            emailAddressAlreadyExists: 'Ein Account mit dieser Email-Adresse existiert bereits',
            atLeastCharacters: 'Die Bezeichnung muss mindestens {num} Zeichen lang sein',
            mustBeAValidEmailAdress: 'Bitte geben Sie eine gültige Email-Adresse ein',
            bothPasswordsMustBeTheSame: 'Die beiden Passwort-Felder stimmen nicht überein',
            alphaNumSpaceOnly: 'Erlaubte Zeichen: Buchstaben, Zahlen und . - _ °',
            alphaNumOnly: 'Erlaubte Zeichen: Buchstaben, Zahlen, - und _ (keine Leerschläge)',
            alphaNumDot: 'Erlaubte Zeichen: Buchstaben, Zahlen und .',
            orgNameOnly: 'Erlaubte Zeichen: Buchstaben, Zahlen und -, wobei der - nicht am Anfang oder Ende sein darf',
            objectNameAlreadyExists: 'Doppelter Name: Es existiert bereits ein Objekt mit diesem Namen',
            atMostCharacters: 'Die maximale Textlänge von {num} Zeichen wurde überschritten',
            appNameAlreadyExists: 'Doppelter Name: Es existiert bereits eine App mit diesem Namen',
            projectNameAlreadyExists: 'Doppelter Name: Es existiert bereits ein Projekt mit diesem Namen',
            sfxdataNameAlreadyExists: 'Doppelter Name: Es existiert bereits ein Asset mit diesem Namen',
            formNameAlreadyExists: 'Doppelter Name: Es existiert bereits ein Asset mit diesem Namen',
            mediaNameAlreadyExists: 'Doppelter Name: Es existiert bereits ein Asset mit diesem Namen',
            datasetNameAlreadyExists: 'Doppelter Name: Es existiert bereits eine Tabelle mit diesem Namen',
            materialmapperNameAlreadyExists: 'Doppelter Name: Es existiert bereits eine Material Mapper mit diesem Namen',
            datasourceNameAlreadyExists: 'Doppelter Name: Es existiert bereits ein Asset mit diesem Namen',
            required: 'Dieses Feld muss ausgefüllt werden',
            fileTypeNotAllowed: 'Dieser Dateityp kann hier nicht hochgeladen werden. Erlaubte Dateiendungen: {fileTypes}',
            fileTooBig: 'Ihre Datei ({{filesize}}) übersteigt die Maximalgrösse von {{maxFilesize}} MB',
            userPassOrNameIsWrong: 'Der Benutzername oder das Passwort wurden nicht akzeptiert.',
            userIsBlocked: 'Der Nutzer ist aufgrund zu vieler Fehlversuche blockiert worden. Bitte wenden Sie sich' +
                ' an den Administrator',
            mustBeNumeric: 'Der Wert muss eine Zahl sein',
            mustBeAValidURL: 'Bitte geben Sie eine gültige URL im Format https://www.test.ch ein',
        },
        showMorePivotPositions: 'Alle Pivot-Positionen anzeigen',
        pivot: {
            source: 'Original',
            top: 'Oben',
            left: 'Links',
            right: 'Rechts',
            center: 'Zentriert',
            base: 'Unten',
            front: 'Vorne',
            back: 'Hinten'
        },
        nav: {
            blueprintEdit: 'Edit',
            blueprint: 'BluePrint',
            export: 'Export',
            template_general: 'Allgemein',
            templates: 'Templates',
            regex: 'Regex',
            share: 'Share',
            gallery: 'Galerie',
            logs: 'Logs',
            projects_stats: 'Logs',
            cclights: 'CC LIGHT',
            appPanels: 'APP PANEL',
            sfxdata_meta_data: 'Meta Infos',
            font: 'Schrift',
            profiles: 'Profile',
            exportprofiles: 'Profile',
            exportprofiles_general: 'Allgemein',
            projects_preview: '3D Web-Ansicht',
            organizations_overview: 'Organisationen',
            organizations_add: 'Organisationen',
            organization: 'Organisation',
            organization_dashboard: 'Dashboard',
            help: 'Hilfe',
            texture: 'Textur',
            texture_general: 'Allgemein',
            texturebackbutton: 'Zurück',
            blueprintsbackbutton: 'Zurück',
            add_texture: 'Textur hinzufügen',
            projects_details: 'Share',
            projects_gallery: 'Galerie',
            projects_home: 'Home',
            versions: 'Versionen',
            instance: 'Instanz',
            instance_general: 'Allgemein',
            costOverview: 'Kosten',
            saasServices: 'SAAS SERVICES',
            saasAgb: 'SAAS AGB',
            csv_import: 'CSV Import',
            createVirtualMachine: 'VM erstellen',
            vmOverview: 'VM Übersicht',
            vmUsageAll: 'VM ALL',
            myVmUsage: 'VM Nutzung',
            vmUsage: 'VM Nutzung',
            adminproject: 'General Projects',
            taskmanager: 'Taskmanager',
            scene_general: 'Allgemein',
            scenebackbutton: 'Zurück',
            vmbackbutton: 'Zurück',
            scene: 'Szene',
            scenes: 'Szenen',
            service_edit: 'Allgemeines',
            rolebackbutton: 'Zurück',
            permissionsbackbutton: 'Zurück',
            permission_edit: 'Allgemeines',
            permissions: 'Berechtigungen',
            roles: 'Rollen',
            material_general: 'Allgemeines',
            materials: 'Materialien',
            team_general: 'Allgemeines',
            teams: 'Teams',
            organizing: 'Struktur',
            qrcodes: 'QR-Codes',
            materialmappers: 'Material-Mapper',
            services: 'Services',
            materialbackbutton: 'Zurück',
            materialmapbackbutton: 'Zurück',
            servicebackbutton: 'Zurück',
            teambackbutton: 'Zurück',
            sfxdata_export_profiles: 'Exportprofile',
            files: 'Dateien',
            library_files: 'Dateien',
            library_3d_files: 'Dateien',
            access_denied: 'Kein Zugriff',
            users: 'Benutzer',
            tags: 'Tags',
            admin: 'Admin',
            form_general: 'Allgemeines',
            formbackbutton: 'Zurück',
            projects: 'Projekte',
            project: 'Projekte',
            media: 'Medien',
            forms: 'Formulare',
            addMedia: 'Medien-Objekt erstellen',
            uploadMediaFile: 'Medien-Datei hochladen',
            logout: 'abmelden',
            apps: 'Applikationen',
            app: 'Applikationen',
            account: 'Benutzerkonto',
            organizations: 'Organisationen',
            Organization: 'Organisation',
            project_scene: "Szene",
            project_general: "Allgemeines",
            project_3ddata: "3D-Daten",
            project_media: 'Medien',
            project_datasets: "Datenquellen",
            project_datasources: "Datenquellen",
            report_general: "Allgemeines",
            sfxdata_general: "Allgemeines",
            sfxdata_general_assembly: "Allgemeines",
            sfxdata_data_sets: "Datenquellen",
            sfxdata_media: "Medien",
            sfxdata: "3D-Daten",
            app_general: "Allgemeines",
            media_general: "Allgemeines",
            font_general: "Allgemeines",
            user_general: "Allgemeines",
            app_project: "Projekt",
            data: "Daten",
            data_tables: "Tabellen",
            data_sources: "Datenquellen",
            datasources: "Datenquellen",
            data_sources_general: "Allgemeines",
            datasets: "Tabellen",
            dataset_general: "Allgemeines",
            resources: "Resourcen",
            library: "Bibliothek",
            reports: "Statistiken",
            report: "Statistiken",
            modelbackbutton: "Zurück",
            assemblybackbutton: "Zurück",
            reportbackbutton: "Zurück",
            projectsbackbutton: "Zurück",
            projectbackbutton: "Zurück",
            appbackbutton: "Zurück",
            webappbackbutton: "Zurück",
            sfxbackbutton: "Zurück",
            mediabackbutton: "Zurück",
            userbackbutton: "Zurück",
            datasetbackbutton: "Zurück",
            datasourcebackbutton: "Zurück",
            metasetbackbutton: "Zurück",
            textbackbutton: "Zurück",
            organizationbackbutton: "Zurück",
            qrcodebackbutton: "Zurück",
            materialmapperbackbutton: "Zurück",
            sfxdata_linked_data: "Verknüpfte 3D-Daten",
            texts: "Texte",
            text_general: "Allgemein",
            qrcode_general: "Allgemein",
            materialmapper_general: "Allgemein",
            project_linked_data: "Verknüpfte Daten",
            sfxdata_connection_list: "Alle Verknüpfungen",
            sfxdata_assembly_update: "Update",
            addnew3ddata: "Neues 3D-Objekt",
            Upload: "Datei hochladen",
            Config: "Konfiguration",
            metasets: "Metasets",
            metaSets: "Metasets",
            webapp_linked_data: "Verknüpfte Daten",
        },
        exportPrompt: {
            generatePartList: 'Bauteile-Liste-Tabelle am Schluss anhängen',
            pdfOptions: 'PDF Optionen',
            export: "Exportieren",
            word: "Word",
            wordOptions: "Word Optionen",
            pdf: "PDF",
            PDFReport: "PDF Report",
            template: "Vorlage",
            autoGenerateLegend: "Legende generieren lassen",
            metaSet: "Meta Set",
            metaField: "Meta Field",
            download: "Herunterladen",
            pleaseSelectAlternativeNames:
                "Bitte wählen Sie ein Metaset und ein Metafeld aus, wenn Sie alternative Bauteilnamen für die Legende anzeigen möchten",
        },
        events: {
            instanceDataMetaValuesUpdated: 'Der Wert wurde aktualisiert',
            addedNextButtons: 'Die Buttons wurden hinzugefügt',
            instanceUpdated: 'Die Instanz wurde aktualisiert',
            metaValuesUpdated: 'Ein Meta-Wert wurde aktualisiert',
            tagUpdated: 'Der Tag wurde aktualisiert',
            metaFieldCreated: 'Ein Meta-Feld wurde erstellt',
            metaSetCreated: 'Ein Metaset wurde erstellt',
            appUpdated: 'Die Applikation wurde aktualisiert.',
            appCreated: 'Eine neue App wurde erstellt.',
            appDeleted: 'Die App wurde gelöscht',
            appMetaValuesUpdated: 'Der Wert wurde aktualisiert.',
            dataSetCreated: 'Das Dataset wurde erstellt.',
            dataSetDeleted: 'Das Dataset wurde gelöscht',
            dataSetUpdated: 'Das Dataset wurde aktualisiert.',
            dataSourceMetaValuesUpdated: 'Der Wert wurde aktualisiert.',
            dataSourceCreated: 'Die Datenquelle wurde erstellt.',
            dataSourceDeleted: 'Die Datenquelle wurde gelöscht',
            dataSourceUpdated: 'Die Datenquelle wurde aktualisiert.',
            datasetMetaValuesUpdated: 'Der Wert wurde aktualisiert.',
            formUpdated: 'Das Formular wurde aktualisiert.',
            formCreated: 'Ein neues Formular wurde erstellt.',
            formDataCleared: 'Formulardaten wurden gelöscht.',
            mediaCreated: 'Ein neues Medien-Objekt wurde erstellt.',
            mediaDeleted: 'Das Medien-Objekt wurde gelöscht',
            mediaUpdated: 'Das Medien-Objekt wurde aktualisiert.',
            mediaMetaValuesUpdated: 'Der Wert wurde aktualisiert.',
            metaFieldDeleted: 'Das MetaField wurde gelöscht',
            metaFieldUpdated: 'Das MetaField wurde aktualisiert',
            organizationUpdated: 'Die Organisation wurde aktualisiert',
            organizationCreated: 'Die Organisation wurde erstellt.',
            organizationDeleted: 'Die Organisation wurde gelöscht.',
            pictureRemoved: 'Das Bild wurde entfernt.',
            projectCreated: 'Ein neues Projekt wurde erstellt.',
            projectDeleted: 'Das Projekt wurde gelöscht',
            projectUpdated: 'Das Projekt wurde aktualisiert.',
            projectMetaValuesUpdated: 'Der Wert wurde aktualisiert.',
            qrcodeCreated: 'Ein neuer QR-Code wurde erstellt.',
            qrcodeDeleted: 'Der QR-Code wurde gelöscht',
            qrcodeUpdated: 'Der QR-Code wurde aktualisiert.',
            qrcodeMetaValuesUpdated: 'Der Wert wurde aktualisiert.',
            reportCreated: 'Ein neuer Report wurde erstellt.',
            reportUpdated: 'Der Report wurde aktualisiert.',
            reportMetaValuesUpdated: 'Der Wert wurde aktualisiert.',
            sceneCreated: 'Eine neue Szene wurde erstellt.',
            sceneDeleted: 'Die Szene wurde gelöscht',
            sceneUpdated: 'Die Szene wurde aktualisiert.',
            sceneMetaValuesUpdated: 'Der Wert wurde aktualisiert.',
            sfxDataCreated: 'Ein neues 3D-Objekt wurde erstellt.',
            sfxDataDeleted: 'Das 3D-Objekt wurde gelöscht',
            sfxDataUpdated: 'Das 3D-Objekt wurde aktualisiert.',
            sfxDataMetaValuesUpdated: 'Der Wert wurde aktualisiert.',
            tagDeleted: 'Der Tag wurde gelöscht.',
            tagsAdded: 'Der Inhalt wurde getaggt',
            tagCreated: 'Der Tag wurde erstellt',
            tagsRemoved: 'Der Tag wurde entfernt',
            textUpdated: 'Der Text wurde aktualisiert.',
            textCreated: 'Der Text wurde erstellt.',
            userUpdated: 'Die Nutzerdaten wurden aktualisiert',
            userCreated: 'Ein neuer Nutzer wurde angelegt',
            metaValuesUnset: 'Meta value deleted from database'
        },
        backtooverview: 'Zurück zur Übersicht',
        projectloading: 'Projekt lädt',
        apploading: 'Applikation lädt',
        STATUS: 'STATUS',
        CONTRIBUTORS: 'ERSTELLT VON',
        entertitlehere: 'Titel hier eingeben',
        enterdeschere: 'Beschreibung hier eingeben',
        downloadType: '{type} herunterladen',
        createNewType: '{type} erstellen',
        selectedType: 'Ausgewähltes {type}',
        addNewType: '{type} hinzufügen',
        deleteType: '{type} löschen',
        createNewTypeFile: '{type} erstellen',
        updateType: '{type} aktualisieren',
        createNewApp: 'Neue App erstellen',
        createNewReport: 'Neue Statistik anlegen',
        createNewProject: 'Neues Projekt erstellen',
        createNewSfxdata: '3D-Daten importieren',
        updateSfxdata: '3D-Daten aktualisieren',
        createNewMedia: 'Neue Medien-Datei erstellen',
        createNewUser: 'Neuen Benutzer anlegen',
        createNewMetaField: 'Neues MetaField anlegen',
        createNewMetaSet: 'Neues MetaSet anlegen',
        createNewOrganization: 'Neue Organisation erstellen',
        createNewText: 'Neuen Textblock erstellen',
        createNewQrCode: 'Neuen QR Code erstellen',
        createNewMaterialMapper: 'Neuer Material-Mapper',
        createNewForm: 'Neues Formular erstellen',
        SAVE: 'SPEICHERN',
        REMOVE: 'ENTFERNEN',
        makeCodePretty: 'Code formatieren',
        items: 'Objekte',
        imageupload: 'Bilddatei hierher ziehen oder klicken',
        fileupload: 'Datei hierher ziehen oder klicken',
        Media: "Medien",
        Datasets: "Datenquellen",
        ChooseProjectForApp: 'Wählen Sie ein Basis-Projekt für diese App',
        deletefield: 'FELD LÖSCHEN',
        Anumberbetween: 'Eine Zahl zwischen {num} und {num2}',
        duplicateFileError: 'Eine Datei mit dem Namen {fileName} existiert bereits. Bitte benennen Sie Ihre Datei um oder wählen sie eine andere Datei aus.',
        addnewfield: 'Neues Feld hinzufügen',
        addnewcolumn: 'Neue Spalte hinzufügen',
        savefield: 'Feld speichern',
        editfield: 'Feld "{field}" bearbeiten',
        fieldCannotBeEditedWhenData: 'Das Feld kann nicht bearbeitet werden, wenn in der Tabelle bereits Daten dazu befüllt sind.',
        NotANumber: 'Der angegebene Wert ist keine Zahl',
        createnew: 'Neu hinzufügen',
        filtering: 'Filtern',
        resources: 'Resourcen',
        library: "Bibliothek",
        addconnection: "Verknüpfung wird hinzugefügt",
        removeconnection: "Verknüpfung wird entfernt",
        LinkedData: 'Verknüpfte Daten',
        LocalLinkedItems: 'Verknüpfte Daten',
        localLinkedItems: 'Verknüpfte Daten',
        createNewDataSet: 'Neue Tabelle erstellen',
        createNewDataSource: 'Neue Datenquelle erstellen',
        forms: {
            isDefaultOrganization: 'Ist die Standardorganisation',
            default: 'Standard',
        },
        meta: {
            type: {
                int: 'Ganzzahl',
                str: 'Zeichenkette',
                json: 'JSON',
                float: 'Gleitkommazahl',
                enum: 'Aufzählung',
                dataset: 'Tabelle',
                datarow: 'Daten-Zeile',
                bool: 'Wahrheitswert',
                clr: 'Farbe',
                v2: 'Vektor 2',
                v3: 'Vektor 3',
                v4: 'Vektor 4',
                time: 'Zeit',
                datetime: 'Zeit und Datum',
                date: 'Datum',
                project: 'Projekt',
                password: 'Passwort',
                repeatPassword: 'Passwort wiederholen',
                xform3: 'Xform3',
                xform2: 'Xform2',
                email: 'Email',
                ip: 'IP',
                ipv6: 'Ipv6',
                uri: 'Uri',
                range: 'Bereich',
                asset: {
                    feed: 'Feed',
                    sensor: 'Sensor'
                },
            }
        },
        'Legacy project id': 'Legacy Projekt-ID',
        Type: 'Typ',
        'Last Update': 'Mut. Datum',
        Edit: 'Aktionen',
        delete: 'Löschen',
        assembly: '3D Gruppe',
        helper: 'vHelper',
        model: '3D Element',
        orgVisibilityTypes: {
            '0': 'Privat',
            '1': 'Öffentlich',
            '2': 'Organisationsübergreifend',
        },
        teamVisibilityTypes: {
            '0': 'privat',
            '1': 'öffentlich',
            '2': 'global (neue User werden autom. hinzugefügt)'
        },
        mediaCategory: {
            '0': 'Applikation',
            '1': 'Audio',
            '2': 'Schriftart',
            '3': 'Bild',
            '5': 'Message',
            '6': 'Model',
            '7': 'MultiPart',
            '8': 'Text',
            '9': 'Video'
        },
        vform: {
            invalidEmailFormat: 'Ungültige Email-Adresse',
            legend: "Legende",
            legendPlaceholder: "Legenden-Platzhalter",
            noCaptionsDetected: "Keine Beschriftungen gefunden",
            partLegend: "Stückliste Legende",
            titleAlignment: "Titel Ausrichtung",
            textAlignment: "Text Ausrichtung",
            partLegendLoading: "Stückliste wird geladen",
            video: 'Video',
            audio: 'Audio',
            courseBlock: 'Course Nav',
            sendEmailToAddress: 'Send email to',
            Checkout: 'Checkout',
            HistoryBack: 'History back',
            FirstSlide: 'Erster Slide',
            LastSlide: 'Letzter Slide',
            FirstStep: 'Erster Step',
            LastStep: 'Letzter Step',
            formSummary: 'Formular Zusammenfassung',
            vimeo: 'Vimeo',
            youtube: 'Youtube',
            other: 'Anderes',
            embed: 'URL Einbetten',
            directEmbed: 'Direkt einbetten',
            upload: 'Direkter Upload',
            chart_line: "Line",
            chart_text: "Text",
            sensor: 'Sensordaten',
            codeSnippet: 'Code',
            translationMessage: "{key} übersetzung für: {content}",
            oldVersionMessage: "Sie haben eine vForm geöffnet, welche mit einer alten Version von vSTAGE erstellt wurde. Wenn Sie speichern, wird die vForm auf die neuste Version aktualisiert. Bitte prüfen Sie, ob alles wie gehabt funktioniert.",
            steps: "Steps",
            blocks: "Bausteine",
            button: "Button",
            login: "Login",
            logout: "Logout",
            editor: "Formular Editor",
            checkoutButton: "Checkout Button",
            preview: "Vorschau",
            headline: "Titel",
            website: "Website",
            singlechoice: "single choice",
            multichoice: "multi choice",
            textfield: "Text",
            shortanswer: "Texteingabefeld",
            helloworld: "Hallo Welt",
            shortLorem: "Text ...",
            longLorem: "Lorem ipsum ..",
            image: "Bild",
            qrcode: "QR Code",
            stepControl: "Step Control",
            projectSwitch: "Projekt-Switch",
            metaListFilter: "Meta List Filter",
            mediaLoader: "Medien-Anzeige",
            divider: "Trennstrich",
            verticalSpace: "Vertical Space",
            slide: "Slide",
            UnnamedStep: "Neuer Step",
            NoAction: "Keine Aktion",
            NextSlide: "Next Slide",
            GoToStep: "Go to Step",
            GoToSlide: "Go to Slide",
            newStep: "Neuer Step",
            properties: "Properties",
            stepProperties: "Step",
            cannotSelectStepsFromOldVform: "You will be able to select steps from this project once you convert it to the new vform version.",
            nextStepButtonsAdded: "'Next Button' was successfully added to each step",
            link: "Link",
            iframe: "iFrame",
            newTab: "Open in new tab",
            loseChangesWarning: "You will lose all your changes. Continue?",
            doNotShowDeleteElementConfirmation: "Diese Mitteilung nicht mehr anzeigen",
            publishButton: "Projekt publizieren",
            publishvformToAppMessage: "Publish started... You will receive an email with the outcome.",
            unpublishvformToAppMessage: "Unpublish started... You will receive an email with the outcome.",
            publishTragetWebAppRequired: "Please select target webapp.",
            selectTragetWebAppPlaceholder: "Select target webapp",
            unpublish: "Unpublish",
            republish: "Update",
            listOfWebAppsWhereVformIsPublished: "Veröffentlicht in:",
            textArea: "Mehrzeiliges Textfeld",
            metaInfo: "Meta Info",
            partInfovStageOnly: "Part Info block is visible only in vStage",
            partInfo: "Bauteil-Info",
            selectInstanceFromScene: "You can select an instance to view more details",
            selectInstanceNotFound: "Selected Instance not found / no extra info",
            sureClearingFormData: "Are you sure you want to clear all form data?",
            clearFormData: "Clear Form Data",
            hotspots: "Hotspots",
            learningDot: "Hotspot",
            possibleAnswers: "Possible answers",
            correct: "Correct",
            learningDots: "Basu-Dots",
            addLearningDot: "Add Label",
            listMatchpanel: "Case 1",
            listMatchimage: "Case 2",
            listMatch: "List Match",
            listMatchTextInput: "List Match - Text Input",
            addHotspot: "Add Hotspot",
            hotspot: {
                popup: 'Popup',
                goto: 'Navigate'
            },
            buttonStyle: {
                Text: 'Text',
                Arrow: 'Arrow',
                TextAndArrow: 'Text & Arrow'
            },
            isMasterPanel: 'Is Global',
            localPanels: 'Local Panels',
            globalPanels: 'Global Panels',
            hideBottomBar: 'Hide Bottom Bar',
            autoFit: "Auto Fit",
            collapsible: "Collapsible",
            initiallyCollapsed: "Initial Collapsed",
            showHideSliders: "Show Additional options",
            noPin: "No pin",
            pinTop: "Pin Top",
            pinTopRight: "Pin Top Right",
            pinTopLeft: "Pin Top Left",
            pinLeft: "Pin Left",
            pinRight: "Pin Right",
            pinBottom: "Pin Bottom",
            pinBottomLeft: "Pin Bottom Left",
            pinBottomRight: "Pin Bottom Right",
            autoPlay: "Auto Play",
            loop: "Loop",
            templates: "Templates",
            missingTemplate: "Template fehlt oder wurde gelöscht. Wir empfehlen, das aktuell angehängte Template zu entfernen und ein neues auszuwählen.",
            removeTemplate: "Template entfernen",
            noTemplatesFound: "Keine Templates gefunden.",
            viewAllTemplates: "Alle Templates anzeigen",
            close: "Schliessen",
            templatePreviewer: "Template-Vorschau",
            templateAssetNotFound: "Template-Asset für Ihre Organisation nicht gefunden",
            templateEditor: "Template-Editor",
            templateType: "Template-Type",
            noTemplateSelected: "Kein Template ausgewählt.",
            templateIsEmpty: "Template-Name darf nicht leer sein.",
            templateOverwrite: "Sie sind dabei, ein vorhandenes Template zu überschreiben.",
            createNewTemplate: "Neues Template erstellen",
            selectTemplate: "Template wählen zum Überschreiben oder neu erstellen",
            saveTemplate: "Template speichern",
            saveChanges: "Änderungen speichern",
            renameTemplate: "Template umbenennen",
            deleteTemplateQuestion: 'Sind Sie sicher, dass Sie das Template "{templatename}" löschen möchten?',
        },

        isCorrectAnswer: "Ist korrekt",
        isChecked: "Ist ausgewählt",
        cclight: {
            notFound: "404 Seite nicht gefunden",
            notFoundMessage: "Seite wurde nicht gefunden. Bitte überprüfen Sie die URL oder wenden Sie sich an den Administrator.",
            unauthorized: "401 Nicht autorisiert",
            unauthorizedMessage: "Kein Zugriff – bitte wenden Sie sich an Ihren Administrator",
            goBack: "Zurück",
        },
        rotLocScaleSetterPrompt: "Bitte beachten Sie, dass dieses Widget nun dasselbe Koordinatensystem wie vSTAGE verwendet. Dadurch wird die Positionierung bei allen vSTAGE-Präsentationen entsprechend angepasst.",
    }
};



const i18n = new VueI18n({
    locale: 'de', // set locale
    messages, // set locale messages
    silentTranslationWarn: true, //remove the annying messages about strings not being strings when in fact they ARE strings
});
export default i18n;