<template>
    <div class="">
        <div class="row">
            <div v-if="!showProcessingMessage && !showSpinner" class="cell col-5 pl-0">
                <span>{{ $t("type.MaterialMapper") }}</span>
                <select
                    class="custom-select darker col-12 mb-2"
                    v-model="selectedMaterialMapperId"
                >
                    <option
                        :key="index"
                        v-for="(item, index) in $store.getters.getMaterialmappersByListName(materialmapperList)"
                        :value="item.id"
                    >
                        {{ item.name }}
                    </option>
                </select>

                <span>{{ $t("type.MetaSet") }}</span>
                <select
                    class="custom-select darker col-12 mb-2"
                    v-model="selectedMetaSetId"
                    v-on:change="loadMetaFields()"
                >
                    <option
                        :key="index"
                        v-for="(item, index) in $store.getters.getMetaSets"
                        :value="item.id"
                    >
                        {{ item.name }}
                    </option>
                </select>

                <span>{{ $t("metaSetMaterialField") }}</span>
                <select
                    class="custom-select darker col-12 mb-2"
                    v-model="selectedMaterialFieldId"
                >
                    <option value=""></option>
                    <option
                        :key="index"
                        v-for="(item, index) in $store.getters.getMetaFieldsByListName(
                            this.selectedMetaSetId
                        )"
                        :value="item.id"
                    >
                        {{ item.description ? item.description : item.name }}
                    </option>
                </select>

                <span>{{ $t("metaSetColorField") }}</span>
                <select
                    class="custom-select darker col-12"
                    v-model="selectedColorFieldId"
                >
                    <option value=""></option>
                    <option
                        :key="index"
                        v-for="(item, index) in $store.getters.getMetaFieldsByListName(
                            this.selectedMetaSetId
                        )"
                        :value="item.id"
                    >
                        {{ item.description ? item.description : item.name }}
                    </option>
                </select>
                <Button
                    slot="buttonsRight"
                    class="mb-3 mt-3"
                    @click="startApplyMappingTask"
                    :deactivated="
                        selectedMaterialMapperId &&
                        !selectedMaterialFieldId &&
                        !selectedColorFieldId
                    "
                >
                    <icon type="" />{{ $t("applyMaterialFromMapper") }}
                </Button>
                <span class="message" v-if="taskCompleted">{{
                    $t("applyMaterialTaskCompleted")
                }}</span>
            </div>
            <div class="cell col-5 pl-0 message" v-if="showSpinner">
                <loading-spinner />
                <span class="ml-2">{{ $t("applyMaterialTaskStarting") }}</span>
            </div>
            <div class="cell col-12 pl-0" v-if="showProcessingMessage">
                <span>{{ $t("applyMaterialTaskStarted") }} </span>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/components/forms/Button";
import LoadingSpinner from "@/components/LoadingSpinner";
import Icon from "../Icon";
export default {
    name: "ApplyMaterialMapping",
    components: {
        Button,
        LoadingSpinner,
        Icon
    },
    props: {
        assetId: { type: String, required: true },
    },
    data() {
        return {
            organizationId: "",
            materials: [],
            materialmapperList: "MaterialmapperList",
            forceReRenderKey: 1,
            selectedMaterialMapperId: "",
            selectedMetaSetId: "",
            selectedMaterialFieldId: "",
            selectedColorFieldId: "",
            showProcessingMessage: false,
            showSpinner: false,
            checkTaskFinishedInterval: 0,
            taskCompleted: false,
            email: "",
        };
    },
    computed: {},
    watch: {},

    beforeMount() {
        this.organizationId = this.$store.getters.getAssemblyOrganization(this.assetId);
        this.loadMaterialMappers();
        this.loadMetaSets();
    },
    methods: {
        loadMaterialMappers() {
            let args = {
                keep: {
                    filter: {
                        schemaName: "schemaName eq sfx.materialMapper",
                        default: "organizationId eq " + this.organizationId,
                    },
                },
            };
            this.$store.dispatch("loadMaterialmappers", args);
        },
        loadMetaSets() {
            let args = { add: {} };
            args.add.filter = { default: "organizationId eq " + this.organizationId };
            this.$store.dispatch("loadMetaSets", args);
        },
        loadMetaFields() {
            this.$store.dispatch("loadMetaFields", {
                keep: {
                    id: this.selectedMetaSetId,
                },
                listName: this.selectedMetaSetId,
            });
        },
        checkIfTaskFinished() {
            this.$store
                .dispatch("clientGetPipelineStatus", {
                    id: this.assetId,
                })
                .then((task) => {
                    if (!task.id) {
                        clearInterval(this.checkTaskFinishedInterval);
                    }
                });
        },
        startApplyMappingTask() {
            this.taskCompleted = false;
            this.showSpinner = true;
            let configJson = JSON.stringify({
                mapperId: this.selectedMaterialMapperId,
                sourceMetaSetId: this.selectedMetaSetId,
                sourceMaterialMetaFieldId: this.selectedMaterialFieldId,
                sourceColorMetaFieldId: this.selectedColorFieldId,
                email: this.$store.getters.getUserEmail(this.$store.getters.getCurrentUserId),
                sendMails: true,
                language: this.$cookies.get('lang')
            });

            if (this.selectedMaterialFieldId || this.selectedColorFieldId) {
                this.$store
                    .dispatch("clientAssetToPipeline", {
                        id: this.assetId,
                        type: "applyMaterial",
                        config: configJson,
                    }).then(() => {
                        this.showProcessingMessage = true;
                        this.showSpinner = false;
                        this.checkTaskFinishedInterval = setInterval(() => {
                            this.$store
                                .dispatch("clientGetPipelineStatus", {
                                    id: this.assetId,
                                })
                                .then((task) => {
                                    if (!task) {
                                        clearInterval(this.checkTaskFinishedInterval);
                                        this.showProcessingMessage = false;
                                        this.taskCompleted = true;
                                    }
                                });
                        }, 10000);
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.message {
    display: flex;
}
</style>
